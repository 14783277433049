import { Purchase } from '@mammothclimate/mammoth_be/api/resources/internal';
import { useNavigate } from 'react-router-dom';

import { useGetReference } from 'mc/api/endpoints/references';
import { DASHBOARD_ORDERS_ROUTE } from 'mc/app/routeUtils';
import Icon from 'mc/features/common/components/icon';
import { PurchaseStatus } from '../utils';

import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import ReferenceTile from './reference-tile';

export interface OrderAlertListItemProps {
  purchase: Purchase;
  status: PurchaseStatus;
}

export function OrderAlertListItem({
  purchase,
  status,
}: OrderAlertListItemProps) {
  const navigate = useNavigate();
  const { data: referenceData } = useGetReference(
    purchase.lineItems[0]?.referenceId,
  );
  const { displayValue } = referenceData!!;

  return (
    <div className="py-3">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <ReferenceTile reference={referenceData!!} />
          <div className="ml-4">
            <p>{displayValue}</p>
            <p className="font-medium text-xs text-gray-500">
              #{purchase.externalId}
            </p>
          </div>
        </div>
        <div>
          <Button
            className="text-xs"
            onClick={() => navigate(`${DASHBOARD_ORDERS_ROUTE}/${purchase.id}`)}
            type="button"
            variant="outline"
          >
            {status === PurchaseStatus.ERROR
              ? 'Request Correction'
              : 'Send Reminder'}
          </Button>
        </div>
      </div>
    </div>
  );
}

export interface OrderAlertListProps {
  purchases: Purchase[];
  status: PurchaseStatus;
}

export default function OrderAlertList({
  purchases,
  status,
}: OrderAlertListProps) {
  // HACK: purchases shouldn't be missing line items anyway
  const validPurchases = purchases.filter(
    (purchase) => purchase.lineItems.length > 0,
  );

  return (
    <div className="p-6">
      <div className="flex items-center">
        <div
          className={cn('px-2 py-2 rounded-full', {
            'bg-red-500': status === PurchaseStatus.ERROR,
            'bg-orange-400': status === PurchaseStatus.PENDING,
          })}
        >
          <Icon name="checkmark" width="18px" className="text-white" />
        </div>
        <p className="ml-4 font-medium">
          {status === PurchaseStatus.ERROR
            ? `${purchases.length} invoices have errors`
            : `${purchases.length} orders awaiting invoice`}
        </p>
      </div>
      <ul className="mt-4">
        {validPurchases.map((purchase) => (
          <li key={purchase.id}>
            <OrderAlertListItem purchase={purchase} status={status} />
          </li>
        ))}
      </ul>
    </div>
  );
}
