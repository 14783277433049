import { Invoice } from '@mammothclimate/mammoth_be/api/resources/internal';
import { useTranslation } from 'react-i18next';

import Icon from 'mc/features/common/components/icon';
import { formatDate } from 'mc/features/common/utils';
import { PurchaseWithInvoiceData } from 'mc/routes/dashboard/orders';

import { cn } from '@/lib/utils';
import { PurchaseStatus } from '../utils';

export interface OrderComparisonRowProps {
  invoiceValue?: string | number;
  purchaseValue: string | number;
  title: string;
  skipCheck?: boolean;
}

export function OrderComparisonRow({
  invoiceValue,
  purchaseValue,
  title,
  skipCheck = false,
}: OrderComparisonRowProps) {
  const hasError = skipCheck
    ? false
    : typeof invoiceValue !== 'undefined' && invoiceValue !== purchaseValue;

  return (
    <tr
      className={cn({
        'bg-red-100 text-red-500 font-medium': hasError,
      })}
    >
      <td className="p-4">{title}</td>
      <td className="p-4">{purchaseValue}</td>
      <td className="p-4">{title}</td>
      <td className="p-4">{invoiceValue}</td>
    </tr>
  );
}

export interface OrderComparisonProps {
  purchase: PurchaseWithInvoiceData;
  invoice?: Invoice;
}

export default function OrderComparison({
  purchase,
  invoice,
}: OrderComparisonProps) {
  const { t } = useTranslation();

  const rows: OrderComparisonRowProps[] = [
    // {
    //   invoiceValue: '-',
    //   purchaseValue: '-',
    //   title: 'Product',
    // },
    {
      invoiceValue: invoice?.creationDate
        ? formatDate(invoice?.creationDate)
        : '',
      purchaseValue: formatDate(purchase.creationDate),
      title: 'Order Date',
      skipCheck: true,
    },
    {
      invoiceValue: invoice?.lineItems[0].qty,
      purchaseValue: purchase.lineItems[0].qty,
      title: 'Quantity',
    },
    {
      invoiceValue: invoice?.lineItems[0].unitCost
        ? t('formats.currency.two_decimal', {
            value: invoice?.lineItems[0].unitCost,
          })
        : undefined,
      purchaseValue: t('formats.currency.two_decimal', {
        value: purchase.lineItems[0].unitCost,
      }),
      title: 'Price/Kg',
    },
    {
      invoiceValue: invoice?.lineItems[0].totalCost
        ? t('formats.currency.two_decimal', {
            value: invoice?.lineItems[0].totalCost,
          })
        : undefined,
      purchaseValue: t('formats.currency.two_decimal', {
        value: purchase.lineItems[0].totalCost,
      }),
      title: 'Amount',
    },
    {
      invoiceValue: invoice?.dueDate,
      purchaseValue: purchase.dueDate,
      title: 'Due Date',
    },
    // {
    //   invoiceValue: 'TODO',
    //   purchaseValue: 'TODO',
    //   title: 'Delivery Method',
    // },
  ];

  let automatchMessage = 'Awaiting invoice';
  if (purchase.status === PurchaseStatus.ERROR) {
    automatchMessage = `Automatch found ${purchase.errorCount} errors`;
  } else if (purchase.status === PurchaseStatus.AUTOMATCH) {
    automatchMessage = 'Automatch found 0 errors';
  }

  return (
    <div className="p-8 mt-10 rounded-md drop-shadow-sm bg-white">
      <div className="flex items-center">
        <div
          className={cn('p-2 rounded-full', {
            'bg-red-400': purchase.status === PurchaseStatus.ERROR,
            'bg-orange-500': purchase.status === PurchaseStatus.PENDING,
            'bg-green-500': purchase.status === PurchaseStatus.AUTOMATCH,
          })}
        >
          <Icon name="checkmark" className="text-white" width="18px" />
        </div>
        <p className="ml-2 text-xl">{automatchMessage}</p>
      </div>

      {/* using native table for an unstyled table */}
      <div className="-mx-4 mt-4">
        <table className="w-full text-left font-normal">
          <thead>
            <tr>
              <th colSpan={2} className="p-4 font-medium">
                Order {purchase.id}
              </th>
              <th colSpan={2} className="p-4 font-medium">
                {invoice
                  ? `Invoice ${invoice.id}`
                  : 'Invoice not yet submitted'}
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map(({ invoiceValue, purchaseValue, title, skipCheck }) => (
              <OrderComparisonRow
                key={title}
                invoiceValue={invoiceValue}
                purchaseValue={purchaseValue}
                title={title}
                skipCheck={skipCheck}
              />
            ))}
          </tbody>
        </table>

        {purchase.status === PurchaseStatus.ERROR && invoice && (
          <div className="mt-6">
            <hr />

            <div className="w-full mt-8 grid grid-cols-3 gap-4">
              <div>
                <p className="font-medium text-2xl text-gray-600">
                  {t('formats.currency.two_decimal', {
                    ns: 'global',
                    value: purchase.lineItems[0].totalCost,
                  })}
                </p>
                <p>PO Amount</p>
              </div>
              <div>
                <p className="font-medium text-2xl text-gray-600">
                  {t('formats.currency.two_decimal', {
                    ns: 'global',
                    value: invoice.lineItems[0].totalCost,
                  })}
                </p>
                <p>Invoice Amount</p>
              </div>
              <div>
                {(() => {
                  const difference =
                    purchase.lineItems[0].totalCost -
                    invoice.lineItems[0].totalCost;

                  const isOverpaying = difference < 0;

                  return (
                    <>
                      <p className="font-medium text-2xl text-red-500">
                        {t('formats.currency.two_decimal', {
                          ns: 'global',
                          value: Math.abs(difference),
                        })}
                      </p>
                      <p>{isOverpaying ? 'Overpaying' : 'Underpaying'}</p>
                    </>
                  );
                })()}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
