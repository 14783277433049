import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import I18NextHttpBackend from 'i18next-http-backend';
import yaml from 'js-yaml';
import { initReactI18next } from 'react-i18next';

export const DEFAULT_LNG = 'en';

function getNumberFormatter(
  style: string,
  maximumFractionDigits: number,
  lng: string = DEFAULT_LNG,
  options?: object,
) {
  return new Intl.NumberFormat(lng, {
    style,
    maximumFractionDigits,
    ...options,
  });
}

if (!i18n.isInitialized) {
  i18n
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .use(I18NextHttpBackend)
    .init({
      load: 'languageOnly', // only load from 'en' rather than 'en-US'
      fallbackLng: DEFAULT_LNG,
      ns: ['global', 'errors', 'pages', 'components'],

      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.yml',
        parse: (data: string) => yaml.load(data),
      },

      react: {
        useSuspense: true,
      },

      interpolation: {
        escapeValue: false,
        format: (value, rawFormat, lng) => {
          const [format, ...additionalValues] = (rawFormat || '')
            .split(',')
            .map((v) => v.trim());

          switch (format) {
            case 'decimal_zero_decimal':
              return getNumberFormatter('decimal', 0, lng).format(value);
            case 'decimal_one_decimal':
              return getNumberFormatter('decimal', 1, lng).format(value);
            case 'decimal_two_decimal':
              return getNumberFormatter('decimal', 2, lng).format(value);

            case 'percent_zero_decimal':
              return getNumberFormatter('percent', 0, lng).format(value);
            case 'percent_one_decimal':
              return getNumberFormatter('percent', 1, lng).format(value);
            case 'percent_two_decimal':
              return getNumberFormatter('percent', 2, lng).format(value);

            case 'currency_zero_decimal':
              return getNumberFormatter('currency', 0, lng, {
                currency: additionalValues[0],
              }).format(value);
            case 'currency_two_decimal':
              return getNumberFormatter('currency', 2, lng, {
                currency: additionalValues[0],
              }).format(value);

            default:
              return value;
          }
        },
      },
    });
}

export default i18n;
