import { useContext } from 'react';

import { useGetDriversList } from 'mc/api/endpoints/emissions';
import useOrganizationId from 'mc/api/hooks/use-organization-id';

import { getTimelineFromYear } from '../utils';
import { GlobalFiltersContext } from '../utils/context';
import PerformanceDriverCard from './driver-card';

export default function PerformanceDriversSection() {
  const { emissionsCategory, timelineYear } = useContext(GlobalFiltersContext);
  const organizationId = useOrganizationId();

  const { data: driversData } = useGetDriversList({
    companyId: organizationId,
    category: emissionsCategory,
    timeline: getTimelineFromYear(timelineYear),
  });

  return (
    <section className="-mx-3 flex items-stretch">
      {driversData &&
        driversData.map((props) => (
          <div className="mx-3" key={props.driver}>
            <PerformanceDriverCard
              content={props.driver}
              iconVariant="error"
              title=""
              // tooltipContent={props.driver}
            />
          </div>
        ))}
    </section>
  );
}
