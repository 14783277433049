import { EmissionsLevel } from '@mammothclimate/mammoth_be/api/resources/internal';
import { useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useGetFunctionalUnitEmissions } from 'mc/api/endpoints/emissions';
import { useGetFunctionalUnit } from 'mc/api/endpoints/functional-units';
import useOrganizationId from 'mc/api/hooks/use-organization-id';
import { DASHBOARD_PERFORMANCE_ROUTE } from 'mc/app/routeUtils';
import InputLifecycleSection from 'mc/features/performance/components/input-lifecycle-section';
import { transformFuEmissionsToFuRows } from 'mc/features/performance/utils';
import { GlobalFiltersContext } from 'mc/features/performance/utils/context';
import FuTable from 'mc/features/visualizations/components/fu-table';
import { useTranslation } from 'react-i18next';

export default function ProductsShow() {
  const { fuId } = useParams();
  const { emissionsCategory } = useContext(GlobalFiltersContext);
  const { t } = useTranslation();
  const organizationId = useOrganizationId();

  const { data: fuData } = useGetFunctionalUnit({ externalId: fuId!! });
  const { data: fuEmissionsData } = useGetFunctionalUnitEmissions({
    functionalUnit: fuId!!,
    companyId: organizationId,
  });

  if (!fuId) {
    return <Navigate to={DASHBOARD_PERFORMANCE_ROUTE} replace />;
  }

  const fuTableRows = transformFuEmissionsToFuRows(
    [fuEmissionsData!![0].childEmissions[0]],
    [fuData!!],
  );

  return (
    <div>
      <div className="mt-6 bg-white rounded-lg drop-shadow-sm">
        <div className="py-8 px-6">
          <h3 className="text-2xl font-semibold">
            {t('products_show.title', { ns: 'pages' })}
          </h3>
        </div>

        <hr />

        <FuTable data={fuTableRows} emissionsCategory={emissionsCategory} />
      </div>

      <div className="mt-6 bg-white rounded-lg drop-shadow-sm">
        <InputLifecycleSection parentLevel={EmissionsLevel.FunctionalUnit} />
      </div>
    </div>
  );
}
