import { Outlet } from 'react-router-dom';

// import Navbar from 'mc/features/navigation/components/navbar';
import Sidebar from 'mc/features/navigation/components/sidebar';

export default function WithSidebar() {
  return (
    <div className="bg-gray-50">
      <div className="w-full min-h-screen flex flex-col md:flex-row">
        <div className="min-h-screen border-r border-r-gray-200 md:flex md:basis-[250px] md:flex-none overflow-auto">
          <Sidebar />
        </div>
        <div className="flex-auto">
          <div className="min-h-screen overflow-auto">
            {/* <Navbar /> */}

            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
