import {
  EmissionsLevel,
  EmissionsListSort,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import { Suspense, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useGetEmissionsList } from 'mc/api/endpoints/emissions';
import { useGetCompanyFunctionalUnits } from 'mc/api/endpoints/functional-units';
import useOrganizationId from 'mc/api/hooks/use-organization-id';
import { DASHBOARD_PERFORMANCE_PRODUCTS_INDEX_ROUTE } from 'mc/app/routeUtils';
import Spinner from 'mc/features/common/components/spinner';
import FuTable from 'mc/features/visualizations/components/fu-table';

import { Button } from '@/components/ui/button';

import { getTimelineFromYear, transformFuEmissionsToFuRows } from '../utils';
import { GlobalFiltersContext } from '../utils/context';

export default function FunctionalUnitEmissionsSection() {
  const { emissionsCategory, timelineYear } = useContext(GlobalFiltersContext);
  const [sort] = useState<EmissionsListSort[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const organizationId = useOrganizationId();

  const { data: fuEmissionsData } = useGetEmissionsList({
    companyId: organizationId,
    category: emissionsCategory,
    timeline: getTimelineFromYear(timelineYear),
    parentLevel: EmissionsLevel.Portfolio,
    level: EmissionsLevel.FunctionalUnit,
    sort,
  });

  const { data: organizationFusData } = useGetCompanyFunctionalUnits({
    id: organizationId,
    timeline: getTimelineFromYear(timelineYear),
  });

  const fuTableRows = transformFuEmissionsToFuRows(
    fuEmissionsData!![0].childEmissions,
    organizationFusData || [],
  );

  return (
    <div>
      <Suspense fallback={<Spinner isLoading />}>
        <div className="py-6 px-6 flex items-center justify-between">
          <h3 className="text-2xl font-semibold">
            {t('performance.sections.top_emissions_by_products.title', {
              ns: 'pages',
            })}
          </h3>
          <Button type="button" variant="outline" size="lg" asChild>
            <Link to={DASHBOARD_PERFORMANCE_PRODUCTS_INDEX_ROUTE}>
              {t('performance.sections.top_emissions_by_products.view_all', {
                ns: 'pages',
              })}
            </Link>
          </Button>
        </div>
        <FuTable
          data={fuTableRows}
          emissionsCategory={emissionsCategory}
          onRowClick={(productId) => {
            navigate(
              `${DASHBOARD_PERFORMANCE_PRODUCTS_INDEX_ROUTE}/${productId}`,
            );
          }}
          // onSortClick={(sortCategory) => setSort([sortCategory])}
        />
      </Suspense>
    </div>
  );
}
