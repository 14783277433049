import { useKindeAuth } from '@kinde-oss/kinde-auth-react';

// TODO: this is temporary until we wrap useQuery to pass consistent data on requests:
// https://linear.app/mammothclimate/issue/MAM-309/update-api-hooks-to-pass-in-reactqueryoptions
export default function useOrganizationId() {
  const { getOrganization } = useKindeAuth();

  try {
    return getOrganization().orgCode;
  } catch (e) {
    window.console.error(e);
    return '';
  }
}
