import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { H } from 'highlight.run';
import { Suspense, useEffect } from 'react';

import IconSet from 'mc/features/common/components/icon-set';
import Spinner from 'mc/features/common/components/spinner';

import { Toaster } from '@/components/ui/toaster';

import '../styles/main.scss';
import AppRouter from './app-router';

export default function App() {
  const { user } = useKindeAuth();

  useEffect(() => {
    async function identifyUser() {
      if (!user) {
        return;
      }

      // TODO: our Kinde users should always have emails and ids,
      // but we can also report errors if they don't down the line
      H.identify(user.email as string, {
        id: user.id as string,
      });
    }

    identifyUser();
  }, [user]);

  return (
    <Suspense
      fallback={
        <div className="min-h-screen min-v-screen relative">
          <div className="w-[50px] h-[50px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <Spinner isLoading />
          </div>
        </div>
      }
    >
      <IconSet />

      <AppRouter />

      <Toaster />
    </Suspense>
  );
}
