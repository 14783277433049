import {
  EmissionsCalculation,
  FunctionalUnit,
  Timeline,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import { Reference } from '@mammothclimate/mammoth_be/api/resources/reference';

import pbfcJpg from 'mc/assets/pbfc.jpg';
import { FuTableRow } from 'mc/features/visualizations/components/fu-table';
import { InputTableRow } from 'mc/features/visualizations/components/input-table';

export type LifecycleKey =
  | 'distribution'
  | 'manufacturing'
  | 'materials'
  | 'packaging'
  | 'waste';

// TODO: this is very dumb and assumes one calendar year
// populate with real data for the given user OR have a range (when there's more data)
export function getTimelineFromYear(year: number): Timeline {
  return {
    startDate: `${year}-01-01`,
    endDate: `${year + 1}-01-01`,
  };
}

export const LIFECYCLE_STAGE_TO_COLOR: { [LifecycleKey: string]: string } = {
  distribution: '#5C59E8',
  manufacturing: '#0CAF60',
  materials: '#F5B544',
  packaging: '#FD6A6A',
  waste: '#6AA5FD',
};

const INPUT_TO_LIFECYCLE_STAGE = {
  Cultures: 'MATERIALS',
  Electricity: 'MANUFACTURING',
  'Lactic Acid': 'MATERIALS',
  'Landfill Waste': 'WASTE',
  'Natural Gas': 'MANUFACTURING',
  'Paper Packaging': 'PACKAGING',
  'PET Packaging': 'PACKAGING',
  'Plastic Film': 'PACKAGING',
  'Potato Starch': 'MATERIALS',
  'Recycling Waste': 'WASTE',
  Salt: 'MATERIALS',
  Soymilk: 'MATERIALS',
  Sugar: 'MATERIALS',
  'Sunflower Oil': 'MATERIALS',
  'Transportation (Distribution > Retail)': 'DISTRIBUTION',
  'Transportation (Manufacturing > Distribution)': 'DISTRIBUTION',
  Wastewater: 'WASTE',
  Water: 'MATERIALS',
  'Wheat Flour': 'MATERIALS',
};

const INPUTS = Object.keys(INPUT_TO_LIFECYCLE_STAGE);

const LIFECYCLE_STAGE_INPUTS = {
  DISTRIBUTION: [
    'Transportation (Distribution > Retail)',
    'Transportation (Manufacturing > Distribution)',
  ],
  MATERIALS: [
    'Cultures',
    'Lactic Acid',
    'Potato Starch',
    'Salt',
    'Soymilk',
    'Sugar',
    'Sunflower Oil',
    'Water',
    'Wheat Flour',
  ],
  MANUFACTURING: ['Electricity', 'Natural Gas'],
  PACKAGING: ['Paper Packaging', 'PET Packaging', 'Plastic Film'],
  WASTE: ['Landfill Waste', 'Recycling Waste', 'Wastewater'],
};

// TODO: add composed hooks to handle this automatically
export function transformFuEmissionsToFuRows(
  fuEmissions: EmissionsCalculation[],
  fus: FunctionalUnit[],
): FuTableRow[] {
  const fuById = fus.filter(Boolean).reduce<Record<string, FunctionalUnit>>(
    (acc, fu) => ({
      ...acc,
      [fu.externalId]: fu,
    }),
    {},
  );

  return fuEmissions.map((functionalUnit) => {
    const fuMetadata = fuById[functionalUnit.entityId];

    return {
      id: functionalUnit.entityId,
      name: fuMetadata?.name || '',
      productCode: fuMetadata?.externalId || functionalUnit.entityId || '',
      imgUrl: pbfcJpg,

      amountTotal: functionalUnit.totalEmissions,
      amountTotalPercentage: functionalUnit.percentOfParentEmissions,
      amountPerUnit: functionalUnit.emissionsPerUnit,
      amountPerKg: functionalUnit.emissionsPerKg,
      amountBenchmark: functionalUnit.benchmark || 0,

      benchmarkTooltipContent: '',
    };
  });
}

// convenience function to transform emission data into InputTableRows
export function transformInputEmissionsToInputRows(
  inputEmissions: EmissionsCalculation[],
  references: Reference[],
  lifecycleStage: LifecycleKey | undefined,
): InputTableRow[] {
  const referenceById = references.reduce<Record<string, Reference>>(
    (acc, reference) => ({
      ...acc,
      [reference.id]: reference,
    }),
    {},
  );

  const inputNameList = lifecycleStage
    ? // @ts-ignore
      LIFECYCLE_STAGE_INPUTS[lifecycleStage.toUpperCase()]
    : INPUTS;

  return inputEmissions.map((input, idx) => {
    const referenceMetadata = referenceById[input.entityId];

    return {
      id: input?.entityId || '',
      name: referenceMetadata?.displayValue || inputNameList[idx],
      amountTotal: input?.totalEmissions || 0,
      amountTotalPercentage: input?.percentOfParentEmissions || 0,
      amountTotalOfProduct: input?.emissionsPerUnit || 0,
      amountPerKg: input?.emissionsPerKg || 0,

      // TODO: not available yet
      origin: '',
      products: [],
    };
  });
}
