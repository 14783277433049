import {
  CompanyFunctionalUnitsRequest,
  GetFuByExternalIdHttpRequest,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import { useQuery } from '@tanstack/react-query';
import useApiClient from '../hooks/use-api-client';

export const QUERY_KEY_PREFIX = 'functionalUnits';

export function useGetCompanyFunctionalUnits(
  req: CompanyFunctionalUnitsRequest,
) {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, 'organization', req],
    queryFn: () =>
      apiClient.internal.functionalUnits.getAllFunctionalUnitsForCompany(req),
  });
}

export function useGetFunctionalUnit(req: GetFuByExternalIdHttpRequest) {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, req],
    queryFn: () => apiClient.internal.functionalUnits.getFunctionalUnit(req),
  });
}
