import {
  CompanyPurchasesHttpRequest,
  CreatePurchaseRequest,
  PurchaseId,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import { useMutation, useQuery } from '@tanstack/react-query';

import useApiClient from '../hooks/use-api-client';

export const QUERY_KEY_PREFIX = 'purchases';

export function useGetCompanyPurchases(req: CompanyPurchasesHttpRequest) {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, 'organization', req],
    queryFn: () => apiClient.internal.purchases.getCompanyPurchases(req),
  });
}

export function useGetPurchase(req: PurchaseId) {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, req],
    queryFn: () => apiClient.internal.purchases.getPurchase(req),
  });
}

export function useCreatePurchase() {
  const apiClient = useApiClient();

  return useMutation({
    mutationKey: [QUERY_KEY_PREFIX, 'create'],
    mutationFn: (req: CreatePurchaseRequest) =>
      apiClient.internal.purchases.createPurchase(req),
  });
}
