import { ReactNode } from 'react';

export interface SectionProps {
  children: ReactNode;
}

export function Section({ children }: SectionProps) {
  return (
    <section className="p-8 bg-white rounded-lg drop-shadow-sm">
      {children}
    </section>
  );
}
