import { BucketObject } from '@mammothclimate/mammoth_be/api/resources/internal';
import { createContext } from 'react';

type ConversationValues = {
  contents?: string;
  attachment?: BucketObject;
};

export interface ConversationContextValues {
  conversationValues: ConversationValues;
  setConversationValues: (conversationValues: ConversationValues) => void;
}

export const ConversationContext = createContext({
  conversationValues: {},
  setConversationValues: () => {},
} as ConversationContextValues);
