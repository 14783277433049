import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

import { Button } from 'mc/@/components/ui/button';
import PageContainer from 'mc/features/common/components/page-container';
import { Section } from 'mc/features/common/components/section';
import { AssumptionsModal } from 'mc/features/planning/components/assumptions-modal';
import { InsightList } from 'mc/features/planning/components/insight-list';
import { ProjectionTable } from 'mc/features/planning/components/projection-table';
import {
  materialProjectionTableData,
  materialStackedBarChartData,
  mockInsights,
  productProjectionTableData,
  productStackedBarChartData,
} from 'mc/mocks/factories/insights';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
);

const OPTIONS = {
  tension: 0.1,

  responsive: true,

  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    title: {
      display: false,
    },
  },

  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
    },
  },
};

export default function SupplyAndDemandPage() {
  return (
    <PageContainer>
      <div className="w-full flex items-center justify-between">
        <h2 className="text-4xl font-semibold">Supply & Demand</h2>

        <AssumptionsModal onSave={() => window.console.log('saved')} />
      </div>

      <div className="mt-6">
        <div className="grid grid-cols-2 gap-8">
          <div className="col-span-1">
            <Section>
              <div className="flex items-center justify-between">
                <h2 className="text-lg font-semibold">Product Overview</h2>
                <Select
                  onValueChange={(value) => window.console.log({ value })}
                  value="All SKUs"
                >
                  <SelectTrigger className="w-[200px]">
                    <SelectValue placeholder="Filter by SKU" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Select SKUs</SelectLabel>
                      {['All SKUs'].map((value) => (
                        <SelectItem key={value} value={`${value}`}>
                          {value}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="mt-4">
                <Chart
                  type="bar"
                  data={productStackedBarChartData}
                  options={OPTIONS}
                />
              </div>
            </Section>
          </div>
          <div className="col-span-1">
            <Section>
              <div className="flex items-center justify-between">
                <h2 className="text-lg font-semibold">Material Overview</h2>
                <Select
                  onValueChange={(value) => window.console.log({ value })}
                  value="All Materials"
                >
                  <SelectTrigger className="w-[200px]">
                    <SelectValue placeholder="Filter by Material" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Select Materials</SelectLabel>
                      {['All Materials'].map((value) => (
                        <SelectItem key={value} value={`${value}`}>
                          {value}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="mt-4">
                <Chart
                  type="bar"
                  data={materialStackedBarChartData}
                  options={OPTIONS}
                />
              </div>
            </Section>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-4xl font-semibold">Inventory</h2>
        <div className="mt-6">
          <Section>
            <InsightList insights={mockInsights.slice(0, 2)} />
          </Section>
        </div>
        <div className="mt-4">
          <Section>
            <h2 className="text-lg font-semibold">Product List</h2>
            <div className="mt-4">
              <ProjectionTable
                data={productProjectionTableData}
                type="product"
              />
            </div>
          </Section>
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-4xl font-semibold">Production</h2>
        <div className="mt-6">
          <Section>
            <InsightList insights={mockInsights.slice(2, 4)} />
          </Section>
        </div>
        <div className="mt-4">
          <Section>
            <div className="flex justify-between">
              <h2 className="text-lg font-semibold">Material List</h2>
              <Button onClick={() => {}} type="button" variant="outline">
                Create PO
              </Button>
            </div>
            <div className="mt-4">
              <ProjectionTable
                data={materialProjectionTableData}
                type="material"
              />
            </div>
          </Section>
        </div>
      </div>
    </PageContainer>
  );
}
