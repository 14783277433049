import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import {
  DASHBOARD_PERFORMANCE_INPUTS_ROUTE,
  DASHBOARD_PERFORMANCE_PORTFOLIO_ROUTE,
  DASHBOARD_PERFORMANCE_PRODUCTS_INDEX_ROUTE,
} from 'mc/app/routeUtils';

export interface PerformanceNavbarLinkProps {
  title: string;
  to: string;
}

export default function PerformanceNavbar() {
  const { t } = useTranslation();

  const links: PerformanceNavbarLinkProps[] = [
    {
      title: t('performance.sections.portfolio.title', { ns: 'pages' }),
      to: DASHBOARD_PERFORMANCE_PORTFOLIO_ROUTE,
    },
    {
      title: t('performance.sections.products.title', { ns: 'pages' }),
      to: DASHBOARD_PERFORMANCE_PRODUCTS_INDEX_ROUTE,
    },
    {
      title: t('performance.sections.inputs.title', { ns: 'pages' }),
      to: DASHBOARD_PERFORMANCE_INPUTS_ROUTE,
    },
  ];

  return (
    <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
      <ul className="flex flex-wrap -mb-px">
        {links.map(({ title, to }) => (
          <li key={to}>
            <NavLink
              className={({ isActive }) =>
                clsx(
                  'px-5 py-3 border-b-2 hover:text-black hover:border-black dark:hover:text-gray-300 inline-block rounded-t-lg',
                  {
                    'font-semibold text-black border-black': isActive,
                    'border-transparent': !isActive,
                  },
                )
              }
              to={to}
            >
              {title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
