import { CreatePurchaseRequest } from '@mammothclimate/mammoth_be/api/resources/internal';
import { Suspense } from 'react';
import { redirect, useParams } from 'react-router-dom';

import { useCreatePurchase, useGetPurchase } from 'mc/api/endpoints/purchases';
import { DASHBOARD_ORDERS_ROUTE } from 'mc/app/routeUtils';
import Spinner from 'mc/features/common/components/spinner';
import { formatDate } from 'mc/features/common/utils';
import OrderEditForm from 'mc/features/orders/components/order-edit-form';

export default function OrdersEditPage() {
  const { purchaseId } = useParams();

  const { data: purchase } = useGetPurchase(purchaseId || '');
  const { mutateAsync: createPurchase } = useCreatePurchase();

  if (!purchase) {
    redirect(DASHBOARD_ORDERS_ROUTE);
    return null;
  }

  async function handleSubmit(data: CreatePurchaseRequest) {
    try {
      await createPurchase({
        ...data,
        creationDate: formatDate(new Date(data.creationDate)),
        dueDate: formatDate(new Date(data.dueDate)),
      });
    } catch (e) {
      window.console.error(e);
    }
  }

  return (
    <Suspense fallback={<Spinner isLoading />}>
      <OrderEditForm
        purchase={purchase}
        onSubmit={(data) => handleSubmit(data)}
      />
    </Suspense>
  );
}
