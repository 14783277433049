import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import { cn } from 'mc/@/lib/utils';
import { useGetFunctionalUnit } from 'mc/api/endpoints/functional-units';
import {
  DASHBOARD_PERFORMANCE_PRODUCTS_INDEX_ROUTE,
  DASHBOARD_PERFORMANCE_ROUTE,
} from 'mc/app/routeUtils';
import PageHeader from '../../common/components/page-header';

export default function PerformancePageHeader() {
  const { t } = useTranslation();
  const { fuId } = useParams();
  const { data: fuData } = useGetFunctionalUnit({ externalId: fuId!! });
  const location = useLocation();
  const { pathname } = location;

  if (fuData) {
    const breadcrumbLinks = [
      {
        title: t('dashboard.title', { ns: 'pages' }),
        to: DASHBOARD_PERFORMANCE_ROUTE,
      },
      {
        title: t('performance.sections.products.title', { ns: 'pages' }),
        to: DASHBOARD_PERFORMANCE_PRODUCTS_INDEX_ROUTE,
      },
      {
        title: fuData.name,
        to: pathname,
      },
    ];

    return (
      <div>
        <nav>
          <ul className="-mx-1 flex items-center">
            {breadcrumbLinks.map(({ title, to }, idx) => (
              <li className="text-sm" key={to}>
                <NavLink
                  className={cn('mx-1', {
                    'font-semibold': to !== pathname,
                  })}
                  to={to}
                >
                  {title}
                </NavLink>
                {idx < breadcrumbLinks.length - 1 && (
                  <span className="mx-1">/</span>
                )}
              </li>
            ))}
          </ul>
        </nav>

        <div className="mt-2">
          <PageHeader title={fuData.name} subtitle={fuData.externalId} />
        </div>
      </div>
    );
  }

  const title = t('performance.title', { ns: 'pages' });
  const subtitle = t('performance.subtitle', { ns: 'pages' });

  return <PageHeader title={title} subtitle={subtitle} />;
}
