import {
  EmissionsLevel,
  EmissionsListSort,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import { Reference } from '@mammothclimate/mammoth_be/api/resources/reference';
import * as _ from 'lodash-es';
import { useContext, useState } from 'react';

import { useGetEmissionsList } from 'mc/api/endpoints/emissions';
import { useGetReferences } from 'mc/api/endpoints/references';
import useOrganizationId from 'mc/api/hooks/use-organization-id';
import {
  getTimelineFromYear,
  transformInputEmissionsToInputRows,
} from 'mc/features/performance/utils';
import { GlobalFiltersContext } from 'mc/features/performance/utils/context';
import InputTable, {
  InputTableRow,
} from 'mc/features/visualizations/components/input-table';

export default function InputsIndex() {
  const [sort] = useState<EmissionsListSort[]>([]);
  const { emissionsCategory, timelineYear } = useContext(GlobalFiltersContext);
  const organizationId = useOrganizationId();

  const { data: inputEmissionsData } = useGetEmissionsList({
    companyId: organizationId,
    sort,
    level: EmissionsLevel.Input,
    parentLevel: EmissionsLevel.Portfolio,
    category: emissionsCategory,
    timeline: getTimelineFromYear(timelineYear),
  });

  // HACK: should get all of these references in one request
  // TODO: assertions
  const referenceIds = _.map(
    inputEmissionsData!![0].childEmissions,
    'entityId',
  );
  const inputsData = useGetReferences(referenceIds);
  if (_.map(inputsData, 'isLoading').find(Boolean)) {
    return null;
  }

  let inputTableRows: InputTableRow[] = [];

  if (inputEmissionsData) {
    inputTableRows = transformInputEmissionsToInputRows(
      inputEmissionsData[0].childEmissions,
      _.map(inputsData, 'data') as Reference[],
      undefined,
    );
    inputTableRows.length = 5;
  }

  return (
    <div className="bg-white rounded-lg drop-shadow-sm">
      <div className="py-8 px-6 flex justify-between">
        <h3 className="text-2xl font-semibold">Top Emissions by Inputs</h3>
      </div>
      <hr />
      <InputTable
        data={inputTableRows}
        emissionsCategory={emissionsCategory}
        // onSortClick={(sortCategory) => setSort([sortCategory])}
        showPercentageBars
      />
    </div>
  );
}
