import { AspectRatio } from '@radix-ui/react-aspect-ratio';
import { ReactNode } from 'react';

import { cn } from '@/lib/utils';

export interface SpinnerProps {
  children?: ReactNode;
  className?: string;
  isLoading: boolean;
}

export default function Spinner({
  isLoading,
  children,
  className,
}: SpinnerProps) {
  if (isLoading) {
    return (
      <div className={cn('w-[50px]', className)}>
        <AspectRatio ratio={1}>
          <div className="w-full h-full flex justify-center items-center animate-spin">
            <div className="w-full h-full border-4 rounded-full animate-spin border-purple-200 border-t-purple-500" />
          </div>
        </AspectRatio>
      </div>
    );
  }

  return children;
}
