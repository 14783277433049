import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ReactNode } from 'react';

import Icon from 'mc/features/common/components/icon';
import InformationTooltip from 'mc/features/common/components/information-tooltip';

import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { cn } from '@/lib/utils';

export interface TableHeadCellContentsProps {
  children: ReactNode;
  className?: string;
}
export function TableHeadCellContents({
  children,
  className,
}: TableBodyCellContentsProps) {
  return <div className={cn('px-6 py-8', className)}>{children}</div>;
}

export interface TableBodyCellContentsProps {
  children: ReactNode;
  className?: string;
}
export function TableBodyCellContents({
  children,
  className,
}: TableBodyCellContentsProps) {
  return <div className={cn('px-6 py-5 leading-4', className)}>{children}</div>;
}

export interface SortableTableHeadCellProps {
  className?: string;
  onSortClick?: () => void;
  title: string;
  tooltipContent?: ReactNode;
}

export function SortableTableHeadCell({
  className,
  onSortClick,
  title,
  tooltipContent,
}: SortableTableHeadCellProps) {
  if (onSortClick) {
    return (
      <div className={className}>
        <Button
          className="px-2 -mx-2 flex items-center"
          variant="ghost"
          onClick={(e) => {
            e.preventDefault();
            onSortClick();
          }}
          type="button"
        >
          <Icon name="sort" width="8px" height="14px" />
          <span className="mx-1">{title}</span>
          {tooltipContent && (
            <InformationTooltip iconWidth="16px" content={tooltipContent} />
          )}
        </Button>
      </div>
    );
  }

  return <p>{title}</p>;
}

export interface TableBodyDataCellProps {
  className?: string;
  title: string;
  subtitle?: string;
}

export function TableBodyDataCell({
  className,
  title,
  subtitle,
}: TableBodyDataCellProps) {
  return (
    <TableBodyCellContents className={cn(className)}>
      <p className="font-medium text-sm leading-5">{title}</p>
      {subtitle && (
        <p className="mt-1 text-xs text-gray-500 font-medium">{subtitle}</p>
      )}
    </TableBodyCellContents>
  );
}

export interface CommonTableProps {
  data: any[]; // TODO
  columns: any[]; // TODO
  onRowClick: (id: string) => void;
}

export function CommonTable({ data, columns, onRowClick }: CommonTableProps) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className="hover:bg-transparent" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  <TableHeadCellContents>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </TableHeadCellContents>
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                className="cursor-pointer"
                data-state={row.getIsSelected() && 'selected'}
                key={row.id}
                onClick={
                  onRowClick ? () => onRowClick(row.original.id) : undefined
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    className={cn({ 'align-top': cell.column.id !== 'arrow' })}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className="py-8 text-center align-top"
              >
                <p className="font-medium">No results found</p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
