import { faker } from '@faker-js/faker';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';

export interface AssumptionsModalProps {
  onSave: () => void;
}

const skus = Array.from({ length: 5 }).map(() =>
  faker.commerce.productMaterial(),
);

export function AssumptionsModal({ onSave }: AssumptionsModalProps) {
  // TODO: add open state for async onSave
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">Edit Assumptions</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] max-h-[80vh] overflow-auto">
        <DialogHeader>
          <DialogTitle>Edit Assumptions</DialogTitle>
          <DialogDescription>
            Make changes to your assumptions here. Click &apos;Save&apos; when
            you&apos;re done.
          </DialogDescription>
        </DialogHeader>
        <div className="py-4 grid gap-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="forecast" className="text-right">
              Forecast
            </Label>
            <Input id="forecast" className="col-span-3" type="file" />
          </div>
          {skus.map((sku) => (
            <div key={sku} className="first:mt-0 p-4 mt-4">
              <div>
                <p>{sku}</p>

                <div className="mt-2 grid gap-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="safety-stock" className="text-right">
                      Safety Stock
                    </Label>
                    <Input
                      id="safety-stock"
                      value="100"
                      className="col-span-3"
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="throughput" className="text-right">
                      Throughput
                    </Label>
                    <Input id="throughput" value="200" className="col-span-3" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <DialogFooter>
          <Button type="button" onClick={() => onSave()}>
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
