import {
  ConversationMessage,
  ConversationTopicType,
  Invoice,
  Purchase,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import * as _ from 'lodash-es';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useGetTopicConversation } from 'mc/api/endpoints/conversations';
import { useGetCompanyInvoices } from 'mc/api/endpoints/invoices';
import { useGetPurchase } from 'mc/api/endpoints/purchases';
import Icon from 'mc/features/common/components/icon';
import {
  REFETCH_INTERVAL_TIME,
  formatDate,
  getStartOfDate,
} from 'mc/features/common/utils';

import { Avatar, AvatarFallback } from '@/components/ui/avatar';

export interface ConversationMessageEmailProps {
  conversationMessage: ConversationMessage;
}

// Jesus what a terrible name
export function ConversationMessageEmail({
  conversationMessage,
}: ConversationMessageEmailProps) {
  return (
    <div className="flex">
      <Avatar>
        <AvatarFallback>FD</AvatarFallback>
      </Avatar>
      <div className="ml-4 text-sm font-medium">
        <div className="mt-2">{conversationMessage.emailContents}</div>
        {conversationMessage.attachments?.map((attachment) => (
          <div
            key={attachment.filename}
            className="mt-4 text-blue-400 cursor-pointer"
          >
            <Icon name="attachment" width="20px" />
            <p>{attachment.filename}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export function ConversationMessageEvent({ text }: { text: string }) {
  return (
    <div className="p-2 rounded-full flex items-center bg-gray-200">
      <div className="w-[20px] min-w-[20px] h-[20px] min-h-[20px] rounded-full bg-gray-500" />
      <p className="ml-4 text-xs text-gray-500 font-medium">{text}</p>
    </div>
  );
}

export interface ConversationMessageListItemProps {
  item: Purchase | Invoice | ConversationMessage;
}

export function ConversationMessageListItem({
  item,
}: ConversationMessageListItemProps) {
  if ('contractId' in item) {
    // purchase
    return (
      <ConversationMessageEvent
        text={`Purchase order #${item.id} was submitted`}
      />
    );
  }

  if ('purchaseId' in item) {
    // invoice
    return (
      <ConversationMessageEvent text={`Invoice #${item.id} was submitted`} />
    );
  }

  // message
  return (
    <ConversationMessageEmail
      conversationMessage={item as ConversationMessage}
    />
  );
}

export default function ConversationMessageList() {
  // const { sellingCompany } = useContext(ConversationContext);
  // HACK
  // const sellingCompany = 'ZuOFHqHhWu8S7W5DUomd_';

  const { purchaseId } = useParams();
  const {
    data: conversationData,
    error: conversationDataError,
    refetch,
  } = useGetTopicConversation({
    topicRequest: {
      topicId: purchaseId || '',
      topicType: ConversationTopicType.Purchase,
    },
  });
  const { data: purchaseData } = useGetPurchase(purchaseId || '');
  const { data: invoicesData } = useGetCompanyInvoices({});
  const invoiceData = invoicesData?.find(
    (invoice) => invoice.purchaseId === purchaseId,
  );

  // order PO submission, invoice submission, and messages by creation date
  const groupedConversationEvents = _.groupBy(
    [
      purchaseData,
      invoiceData,
      ...(conversationData?.conversationMessages || []),
    ].filter(Boolean),
    (item) => {
      if ('createdAt' in (item || {})) {
        // @ts-ignore
        return getStartOfDate(item.createdAt);
      }

      // @ts-ignore
      return getStartOfDate(item.creationDate);
    },
  );

  // regularly refetch conversations to refresh message list
  useEffect(() => {
    const refetchIntervalId = setInterval(() => {
      // only refetch when
      if (!conversationDataError) {
        refetch();
      }
    }, REFETCH_INTERVAL_TIME);

    return () => {
      window.clearInterval(refetchIntervalId);
    };
  }, [refetch, conversationDataError]);

  return (
    <div>
      <p className="text-center text-xs font-medium">End of history</p>

      {Object.entries(groupedConversationEvents).map(([timestamp, items]) => (
        <div key={timestamp}>
          <p className="mt-10 text-center text-xs font-medium">
            {formatDate(new Date(timestamp))}
          </p>
          <ul>
            {items.map((item) => (
              <li key={item!!.id} className="mt-6">
                <ConversationMessageListItem item={item!!} />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}
