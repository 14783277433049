import { EmissionsCategory } from '@mammothclimate/mammoth_be/api/resources/internal';
import { useTranslation } from 'react-i18next';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

export interface PerformanceGlobalFiltersProps {
  emissionsCategory: EmissionsCategory;
  onEmissionsCategoryChange: (emissionsCategory: EmissionsCategory) => void;
  timelineYear: number;
  onTimelineChange: (year: number) => void;
}

// TODO: populate with real data for the given user
const LATEST_YEAR = 2022;
const TIMELINE_OPTIONS: number[] = Array.from(
  { length: 2 },
  (_, idx) => LATEST_YEAR - idx,
);

export default function PerformanceGlobalFilters({
  emissionsCategory,
  onEmissionsCategoryChange,
  timelineYear,
  onTimelineChange,
}: PerformanceGlobalFiltersProps) {
  const { t } = useTranslation();

  return (
    <div className="p-6 flex justify-between drop-shadow-sm border rounded-lg bg-white">
      <div>
        <p className="font-semibold text-sm">
          {t('performance.global_filters.title', { ns: 'components' })}
        </p>
        <p className="text-xs">
          {t('performance.global_filters.subtitle', { ns: 'components' })}
        </p>
      </div>

      <div className="flex justify-end">
        <div className="text-xs">
          <Tabs
            defaultValue={emissionsCategory}
            value={emissionsCategory}
            onValueChange={(category) =>
              onEmissionsCategoryChange(category as EmissionsCategory)
            }
          >
            <TabsList className="bg-gray-100">
              <TabsTrigger value={EmissionsCategory.Ghg}>
                {t('emissions.ghg.title')}
              </TabsTrigger>
              <TabsTrigger value={EmissionsCategory.Land}>
                {t('emissions.land.title')}
              </TabsTrigger>
              <TabsTrigger value={EmissionsCategory.Water}>
                {t('emissions.water.title')}
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>

        <div className="ml-4">
          <Select
            onValueChange={(value) => onTimelineChange(Number(value))}
            value={`${timelineYear}`}
          >
            <SelectTrigger className="w-[200px]">
              <SelectValue
                placeholder={
                  <span className="font-medium">
                    <span className="text-slate-500">
                      {t('common.date_picker_with_range.placeholder', {
                        ns: 'components',
                      })}
                    </span>
                  </span>
                }
              />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>
                  {t('common.date_picker_with_range.time_period', {
                    ns: 'components',
                  })}
                </SelectLabel>
                {TIMELINE_OPTIONS.map((value) => (
                  <SelectItem key={value} value={`${value}`}>
                    {value}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
}
