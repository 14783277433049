import {
  ChartArea,
  ScriptableChartContext,
  ScriptableLineSegmentContext,
} from 'chart.js';
import { PROJECTION_LINE_SEGMENT } from 'mc/mocks/factories/insights';

export function getGradient(
  ctx: CanvasRenderingContext2D,
  chartArea: ChartArea,
  color: string,
) {
  const gradient = ctx.createLinearGradient(
    0,
    chartArea.bottom,
    0,
    chartArea.top,
  );
  gradient.addColorStop(0.9, color);
  gradient.addColorStop(0, 'rgba(255, 255, 255, 0.5)');
  return gradient;
}

export function getGradientFillBackgroundColor(
  context: ScriptableChartContext,
  color: string,
) {
  const { chart } = context;
  const { ctx, chartArea } = chart;

  if (!chartArea) {
    // happens on initial load
    return 'white';
  }

  return getGradient(ctx, chartArea, color);
}

export function getSegmentBorderDash(context: ScriptableLineSegmentContext) {
  if (context.p0DataIndex > 1) {
    return PROJECTION_LINE_SEGMENT;
  }
  return undefined;
}
