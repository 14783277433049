import {
  EmissionsCategory,
  EmissionsListSort,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import {
  SortableTableHeadCell,
  TableBodyCellContents,
  TableBodyDataCell,
  TableHeadCellContents,
} from 'mc/features/common/components/table';
import { EMISSIONS_CATEGORY_TO_MEASUREMENT_UNITS } from 'mc/features/common/utils/units';
import { GlobalFiltersContext } from 'mc/features/performance/utils/context';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Tooltip,
  TooltipContent,
  TooltipPrimitive,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

import { FuTableRow } from './fu-table';

export interface InputTableRow {
  id: string;
  name: string;

  amountTotal: number;
  amountTotalPercentage: number;
  amountTotalOfProduct: number;
  amountPerKg: number;

  origin: string;
  products: FuTableRow[];
}

export interface InputProductTooltipProps {
  products: FuTableRow[];
}

export function InputProductTooltip({ products }: InputProductTooltipProps) {
  const { t } = useTranslation();

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <span>
            {t('miscellaneous.used_in', { ns: 'global' })}{' '}
            <span className="underline">{products.length} products</span>
          </span>
        </TooltipTrigger>
        <TooltipContent className={cn('p-0 bg-white border text-black')}>
          {products.map((product) => (
            <NavLink
              className={cn('first:mt-0 mt-1 px-2 py-1 block group')}
              key={product.productCode}
              to={product.productCode}
            >
              <p className="font-semibold group-hover:underline">
                {product.name}
              </p>
              <p className="font-medium text-gray-500">
                {t('miscellaneous.product_code')}: {product.productCode}
              </p>
            </NavLink>
          ))}
          <TooltipPrimitive.Arrow className="drop-shadow-sm fill-white" />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export function InputTableNameCell({
  input,
  showPercentageBars,
}: {
  input: InputTableRow;
  showPercentageBars: boolean;
}) {
  const { t } = useTranslation();
  const { emissionsCategory } = useContext(GlobalFiltersContext);

  return (
    <TableBodyCellContents className="font-medium">
      <p className="font-semibold text-base leading-5">{input.name}</p>
      {/* <div className="mt-1">
        <InputProductTooltip products={input.products} />
      </div> */}
      {showPercentageBars && (
        <div className="mt-4">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="relative h-4">
                  <div className="w-full h-full absolute top-0 left-0 rounded-full bg-gray-200" />
                  <div
                    className="h-full absolute top-0 left-0 rounded-full bg-black"
                    style={{
                      width: `${Math.floor(
                        input.amountTotalPercentage * 100,
                      )}%`,
                    }}
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent className={cn('p-3 bg-white border text-black')}>
                <div className="text-center">
                  <p className="text-gray-500">
                    {t(
                      'performance.input_table.breakdown_tooltip.total_emissions_of',
                      { ns: 'components' },
                    )}{' '}
                    {input.name}:
                  </p>
                  <p className="font-semibold">
                    {t('formats.decimal.zero_decimal', {
                      value: input.amountTotal,
                      ns: 'global',
                    })}{' '}
                    {EMISSIONS_CATEGORY_TO_MEASUREMENT_UNITS[emissionsCategory]}{' '}
                    (
                    {t('formats.percent.zero_decimal', {
                      value: input.amountTotalPercentage,
                      ns: 'global',
                    })}
                    )
                  </p>
                  <p className="mt-1 text-gray-500">
                    {t(
                      'performance.input_table.breakdown_tooltip.total_emissions_of_product',
                      { ns: 'components' },
                    )}
                    :
                  </p>
                  <p className="font-semibold">
                    {t('formats.decimal.zero_decimal', {
                      value: input.amountTotalOfProduct,
                      ns: 'global',
                    })}{' '}
                    {EMISSIONS_CATEGORY_TO_MEASUREMENT_UNITS[emissionsCategory]}
                  </p>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      )}
    </TableBodyCellContents>
  );
}

export interface InputTableProps {
  data: InputTableRow[];
  emissionsCategory: EmissionsCategory;
  onSortClick?: (sortCategory: EmissionsListSort) => void;
  showPercentageBars: boolean;
}

export default function InputTable({
  data = [],
  emissionsCategory,
  onSortClick,
  showPercentageBars,
}: InputTableProps) {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const munits = EMISSIONS_CATEGORY_TO_MEASUREMENT_UNITS[emissionsCategory];

  /* eslint-disable react/no-unstable-nested-components */
  // disabled linting because we're defining inline components below
  // this is acceptable since the columns definition is memoized to emissions category
  const columns: ColumnDef<InputTableRow>[] = useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: () => (
          <SortableTableHeadCell className="w-[300px]" title="Input Name" />
        ),
        cell: ({ row }) => (
          <InputTableNameCell
            input={row.original}
            showPercentageBars={showPercentageBars}
          />
        ),
      },
      // {
      //   id: 'origin',
      //   accessorKey: 'origin',
      //   enableSorting: false,
      //   header: () => <SortableTableHeadCell title="Origin" />,
      //   cell: ({ row }) => (
      //     <TableBodyDataCell
      //       className="max-w-[300px]"
      //       title={row.original.origin}
      //     />
      //   ),
      // },
      {
        id: EmissionsListSort.Weight,
        accessorKey: EmissionsListSort.Weight,
        enableSorting: true,
        header: () => (
          <SortableTableHeadCell
            onSortClick={
              onSortClick
                ? () => onSortClick(EmissionsListSort.Weight)
                : undefined
            }
            title="Emissions (kg)"
          />
        ),
        cell: ({ row }) => {
          const { amountPerKg } = row.original;
          const formattedAmount = t('formats.decimal.two_decimal', {
            value: amountPerKg,
            ns: 'global',
          });

          return <TableBodyDataCell title={`${formattedAmount} ${munits}`} />;
        },
      },
      {
        id: EmissionsListSort.Total,
        accessorKey: EmissionsListSort.Total,
        enableSorting: false,
        header: () => (
          <SortableTableHeadCell
            onSortClick={
              onSortClick
                ? () => onSortClick(EmissionsListSort.Total)
                : undefined
            }
            title="Total Emissions"
          />
        ),

        cell: ({ row }) => {
          const { amountTotal, amountTotalPercentage } = row.original;
          const formattedAmount = t('formats.decimal.two_decimal', {
            value: amountTotal,
            ns: 'global',
          });

          const percentText = t('formats.percent.zero_decimal', {
            value: amountTotalPercentage,
            ns: 'global',
          });
          const ofText = t(`emissions.${emissionsCategory.toLowerCase()}.of`);
          const subtitle = `${percentText} ${ofText}`;

          return (
            <TableBodyDataCell
              title={`${formattedAmount} ${munits}`}
              subtitle={subtitle}
            />
          );
        },
      },
    ],
    [munits, emissionsCategory, t, onSortClick, showPercentageBars],
  );
  /* eslint-enable react/no-unstable-nested-components */

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className="hover:bg-transparent" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  <TableHeadCellContents>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </TableHeadCellContents>
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                className="cursor-pointer"
                data-state={row.getIsSelected() && 'selected'}
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    className={cn({ 'align-top': cell.column.id !== 'arrow' })}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className="py-8 text-center align-top"
              >
                <p className="font-medium">No results found</p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
