import { PurchaseId } from '@mammothclimate/mammoth_be/api/resources/internal';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetReference } from 'mc/api/endpoints/references';
import {
  SortableTableHeadCell,
  TableBodyCellContents,
  TableBodyDataCell,
  TableHeadCellContents,
} from 'mc/features/common/components/table';
import { PurchaseWithInvoiceData } from 'mc/routes/dashboard/orders';
import { PurchaseStatus } from '../utils';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { cn } from '@/lib/utils';
import ReferenceTile from './reference-tile';

export interface OrderTableRow extends PurchaseWithInvoiceData {}

export interface OrderTableNameCellProps {
  purchase: PurchaseWithInvoiceData;
}

export function OrderTableNameCell({ purchase }: OrderTableNameCellProps) {
  const { data: referenceData } = useGetReference(
    purchase.lineItems[0].referenceId,
  );
  const { displayValue } = referenceData!!;

  return (
    <TableBodyCellContents>
      <div className="flex items-center">
        <ReferenceTile reference={referenceData!!} />
        <p className="ml-4">{displayValue}</p>
      </div>
    </TableBodyCellContents>
  );
}

export function OrderTableStatusCell({ status }: { status: PurchaseStatus }) {
  // red: [error]
  // orange: [pending]
  // green: [matched, shipped, delivered]

  const classes = cn(
    'h-[48px] px-2 py-2 inline-flex items-center text-center font-medium rounded-md',
    {
      'bg-red-100 text-red-400': status === PurchaseStatus.ERROR,
      'bg-orange-100 text-orange-400': status === PurchaseStatus.PENDING,
      'bg-green-100 text-green-400': status === PurchaseStatus.AUTOMATCH,
    },
  );

  const text = (() => {
    if (status === PurchaseStatus.ERROR) {
      return 'Invoice Error';
    }

    if (status === PurchaseStatus.AUTOMATCH) {
      return 'Automatched';
    }

    return 'Awaiting Invoice';
  })();

  return <div className={classes}>{text}</div>;
}

export interface OrderTableProps {
  data: OrderTableRow[];
  onRowClick?: (purchaseId: PurchaseId) => void;
  onSortClick?: (sortCategory: string) => void;
}

export default function OrderTable({
  data,
  onRowClick,
  onSortClick,
}: OrderTableProps) {
  const { t } = useTranslation();

  /* eslint-disable react/no-unstable-nested-components */
  const columns: ColumnDef<OrderTableRow>[] = useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: () => (
          <SortableTableHeadCell className="w-[200px]" title="Item" />
        ),
        cell: ({ row }) => <OrderTableNameCell purchase={row.original} />,
      },
      {
        id: 'externalId',
        accessorKey: 'externalId',
        header: () => (
          <SortableTableHeadCell
            onSortClick={
              onSortClick ? () => onSortClick('externalId') : undefined
            }
            title="Order #"
          />
        ),
        cell: ({ row }) => (
          <TableBodyDataCell title={row.original.externalId || ''} />
        ),
      },
      {
        id: 'creationDate',
        accessorKey: 'creationDate',
        header: () => (
          <SortableTableHeadCell
            onSortClick={
              onSortClick ? () => onSortClick('creationDate') : undefined
            }
            title="Order Date"
          />
        ),
        cell: ({ row }) => (
          <TableBodyDataCell title={row.original.creationDate} />
        ),
      },
      {
        id: 'dueDate',
        accessorKey: 'dueDate',
        header: () => (
          <SortableTableHeadCell
            onSortClick={onSortClick ? () => onSortClick('dueDate') : undefined}
            title="Due Date"
          />
        ),
        cell: ({ row }) => <TableBodyDataCell title={row.original.dueDate} />,
      },
      {
        id: 'quantity',
        accessorKey: 'quantity',
        header: () => (
          <SortableTableHeadCell
            onSortClick={
              onSortClick ? () => onSortClick('quantity') : undefined
            }
            title="Quantity"
          />
        ),
        cell: ({ row }) => {
          const { qty, unitOfMeasurement } = row.original.lineItems[0];

          return (
            <TableBodyDataCell
              title={`${t('formats.decimal.one_decimal', {
                value: qty,
              })} ${unitOfMeasurement}`}
            />
          );
        },
      },
      {
        id: 'price',
        accessorKey: 'price',
        header: () => (
          <SortableTableHeadCell
            onSortClick={onSortClick ? () => onSortClick('price') : undefined}
            title="Price"
          />
        ),
        cell: ({ row }) => {
          const { totalCost } = row.original.lineItems[0];

          // TODO: formatting + currency
          return (
            <TableBodyDataCell
              title={`${t('formats.currency.two_decimal', {
                value: totalCost,
              })} `}
            />
          );
        },
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: () => (
          <SortableTableHeadCell
            onSortClick={onSortClick ? () => onSortClick('status') : undefined}
            title="Status"
          />
        ),
        cell: ({ row }) => (
          <OrderTableStatusCell status={row.original.status} />
        ),
      },
    ],
    [onSortClick, t],
  );
  /* eslint-enable react/no-unstable-nested-components */

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="bg-white rounded-md drop-shadow-sm">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className="hover:bg-transparent" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  <TableHeadCellContents>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </TableHeadCellContents>
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                className="cursor-pointer"
                data-state={row.getIsSelected() && 'selected'}
                key={row.id}
                onClick={
                  onRowClick ? () => onRowClick(row.original.id) : undefined
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    className={cn({
                      'align-middle': cell.column.id !== 'arrow',
                    })}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="py-8 text-center">
                <p className="font-medium">
                  {t('miscellaneous.no_results_found', { ns: 'global' })}
                </p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
