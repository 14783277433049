import { Suspense, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useGetCompanyInvoices } from 'mc/api/endpoints/invoices';
import { useGetPurchase } from 'mc/api/endpoints/purchases';
import PageContainer from 'mc/features/common/components/page-container';
import Spinner from 'mc/features/common/components/spinner';
import { REFETCH_INTERVAL_TIME } from 'mc/features/common/utils';
import OrderComparison from 'mc/features/orders/components/order-comparison';
import { computeOrderStatuses } from 'mc/features/orders/utils';

export default function OrdersShowPage() {
  const params = useParams();

  const { data: purchaseData, refetch: refetchPurchaseData } = useGetPurchase(
    params.purchaseId!!,
  );
  const { data: invoicesData, refetch: refetchInvoicesData } =
    useGetCompanyInvoices({});

  const invoice = invoicesData?.find(
    (_invoice) => _invoice.purchaseId === params.purchaseId,
  );

  useEffect(() => {
    const refetchIntervalId = setInterval(() => {
      refetchPurchaseData();
      refetchInvoicesData();
    }, REFETCH_INTERVAL_TIME);

    return () => window.clearInterval(refetchIntervalId);
  }, [refetchPurchaseData, refetchInvoicesData]);

  const purchasesWithInvoiceData = computeOrderStatuses(
    purchaseData ? [purchaseData] : [],
    invoicesData,
  );

  return (
    <Suspense fallback={<Spinner isLoading />}>
      <PageContainer>
        <h1 className="font-extralight text-5xl">Order #{purchaseData?.id}</h1>

        <div className="mt-10">
          <OrderComparison
            purchase={purchasesWithInvoiceData[0]}
            invoice={invoice}
          />
        </div>
      </PageContainer>
    </Suspense>
  );
}
