import { useQueries, useQuery } from '@tanstack/react-query';

import useApiClient from '../hooks/use-api-client';

export const QUERY_KEY_PREFIX = 'references';

export function useGetReference(req: string) {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, req],
    queryFn: () => apiClient.reference.references.getReference(req),
  });
}

// HACK to grab references from a list of reference ids
export function useGetReferences(referenceIds: string[]) {
  const apiClient = useApiClient();

  const queries = referenceIds.map((referenceId) => ({
    queryKey: [QUERY_KEY_PREFIX, referenceId],
    queryFn: () => apiClient.reference.references.getReference(referenceId),
  }));

  return useQueries({ queries });
}
