import { CreatePurchaseRequest } from '@mammothclimate/mammoth_be/api/resources/internal';
import { useToast } from 'mc/@/components/ui/use-toast';
import { useCreatePurchase } from 'mc/api/endpoints/purchases';
import { DASHBOARD_ORDERS_ROUTE } from 'mc/app/routeUtils';
import PageContainer from 'mc/features/common/components/page-container';
import PageHeader from 'mc/features/common/components/page-header';
import Spinner from 'mc/features/common/components/spinner';
import { ConversationContext } from 'mc/features/conversations/utils';
import OrderEditForm from 'mc/features/orders/components/order-edit-form';
import { Suspense, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export default function OrdersNewPage() {
  const { mutateAsync } = useCreatePurchase();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { setConversationValues } = useContext(ConversationContext);

  const handleSubmit = useCallback(
    async (data: CreatePurchaseRequest) => {
      // HACK
      const qty = parseFloat(`${data.lineItems[0].qty}`);
      const unitCost = parseFloat(`${data.lineItems[0].unitCost}`);
      const { unitOfMeasurement } = data.lineItems[0];
      const totalCost = qty * unitCost;
      const { dueDate } = data;

      const submissionData: CreatePurchaseRequest = {
        ...data,
        lineItems: [
          {
            ...data.lineItems[0],
            qty,
            totalCost,
          },
        ],
        cost: totalCost,
      };
      const purchaseId = await mutateAsync(submissionData);

      toast({
        title: 'Purchase order created successfully',
        // action: (
        //   <ToastAction
        //     altText="View"
        //     onClick={() => {
        //       navigate(`${DASHBOARD_ORDERS_ROUTE}/${purchaseId}`);
        //     }}
        //   >
        //     View
        //   </ToastAction>
        // ),
      });

      // should really use a reducer here
      setConversationValues({
        contents: `I'd like to place an order of ${qty} ${unitOfMeasurement} of lentils to be delivered by ${dueDate}.  Please see the PO attached for details.  Thank you!`,
        attachment: {
          filename: 'po.pdf',
          bucket: 'mammoth-customer-assets-dev',
          keyPrefix: 'tfd',
        },
      });

      navigate(`${DASHBOARD_ORDERS_ROUTE}/${purchaseId}`);
    },
    [mutateAsync, toast, navigate, setConversationValues],
  );

  return (
    <Suspense fallback={<Spinner isLoading />}>
      <PageContainer>
        <PageHeader title="New purchase order" />

        <div className="mt-8">
          <OrderEditForm onSubmit={handleSubmit} />
        </div>
      </PageContainer>
    </Suspense>
  );
}
