import { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

import ConversationThread from 'mc/features/conversations/components/conversation-thread';
import { ConversationContext } from 'mc/features/conversations/utils';

export default function WithConversation() {
  const [conversationValues, setConversationValues] = useState({});
  const contextValue = useMemo(
    () => ({
      conversationValues,
      setConversationValues,
    }),
    [conversationValues, setConversationValues],
  );

  return (
    <div className="h-full flex">
      <ConversationContext.Provider value={contextValue}>
        <div className="flex-auto">
          <Outlet />
        </div>

        <div className="w-[455px] h-full ml-8 bg-white sticky">
          <ConversationThread />
        </div>
      </ConversationContext.Provider>
    </div>
  );
}
