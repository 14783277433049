import {
  Invoice,
  Purchase,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import { PurchaseWithInvoiceData } from 'mc/routes/dashboard/orders';

export enum PurchaseStatus {
  // errors
  ERROR = 'error',
  // warning
  PENDING = 'pending',
  // success
  AUTOMATCH = 'automatched',
  // unused for now
  // SHIPPED = 'shipped',
  // DELIVERED = 'delivered',
}

// compute errors between PO and invoice (if any)
export function computeOrderStatuses(
  purchases: Purchase[] = [],
  invoices: Invoice[] = [],
) {
  const purchasesWithInvoiceData: PurchaseWithInvoiceData[] = [];

  purchases.forEach((purchase) => {
    let errorCount = 0;
    let costDelta = 0;
    let lineItemDeltas = [];

    const invoice = invoices.find(
      (_invoice) => _invoice.purchaseId === purchase.id,
    );
    if (invoice) {
      costDelta = purchase.cost - invoice.cost;
      if (costDelta) {
        errorCount += 1;
      }

      lineItemDeltas = purchase.lineItems.map((purchaseLineItem, idx) => {
        // NOTE: this assumes PO and invoice line items have the same items and are the same order
        const invoiceLineItem = invoice.lineItems[idx];
        const qtyDelta = purchaseLineItem.qty - invoiceLineItem.qty;
        // const totalCostDelta =
        //   purchaseLineItem.totalCost - invoiceLineItem.totalCost;
        const totalCostDelta = 0;
        const unitCostDelta =
          purchaseLineItem.unitCost - invoiceLineItem.unitCost;

        [qtyDelta, totalCostDelta, unitCostDelta].forEach((delta) => {
          if (delta) {
            errorCount += 1;
          }
        });

        return { qtyDelta, totalCostDelta, unitCostDelta };
      });
      purchasesWithInvoiceData.push({
        ...purchase,
        errorCount,
        status:
          errorCount > 1 ? PurchaseStatus.ERROR : PurchaseStatus.AUTOMATCH,
        costDelta,
        lineItemDeltas,
      });
    } else {
      purchasesWithInvoiceData.push({
        ...purchase,
        errorCount,
        status: PurchaseStatus.PENDING,
        costDelta: 0,
        lineItemDeltas: [],
      });
    }
  });

  return purchasesWithInvoiceData;
}
