import { KindeUser } from '@kinde-oss/kinde-auth-pkce-js';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { DASHBOARD_DEFAULT_ROUTE } from 'mc/app/routeUtils';
import Spinner from 'mc/features/common/components/spinner';

export default function LoginCallback() {
  // TODO: add claims checks whenever we have more organization types
  // to determine where to actually send the user after successful login
  const { isLoading, user } = useKindeAuth();
  const [authUser, setAuthUser] = useState<KindeUser>();

  useEffect(() => {
    if (!isLoading && user) {
      setAuthUser(user);
    }
  }, [isLoading, user]);

  if (authUser) {
    return <Navigate replace to={DASHBOARD_DEFAULT_ROUTE} />;
  }

  return <Spinner isLoading />;
}
