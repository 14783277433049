import { EmissionsCategory } from '@mammothclimate/mammoth_be/api/resources/internal';
import { Suspense, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

import GenericErrorBoundary from 'mc/features/common/components/generic-error-boundary';
import PageContainer from 'mc/features/common/components/page-container';
import Spinner from 'mc/features/common/components/spinner';
import PerformanceGlobalFilters from 'mc/features/performance/components/global-filters';
import PerformanceNavbar from 'mc/features/performance/components/navbar';
import PerformancePageHeader from 'mc/features/performance/components/performance-page-header';
import { GlobalFiltersContext } from 'mc/features/performance/utils/context';

export default function WithPerformanceContext() {
  const [emissionsCategory, setEmissionsCategory] = useState<EmissionsCategory>(
    EmissionsCategory.Ghg,
  );
  const [timelineYear, setTimelineYear] = useState(2022);
  const contextValue = useMemo(
    () => ({
      emissionsCategory,
      setEmissionsCategory,
      timelineYear,
      setTimelineYear,
    }),
    [emissionsCategory, timelineYear],
  );

  return (
    <PageContainer>
      <GlobalFiltersContext.Provider value={contextValue}>
        <PerformancePageHeader />

        <div className="sticky top-4 z-10 bg-gray-50">
          <div className="mt-6">
            <PerformanceGlobalFilters
              emissionsCategory={emissionsCategory}
              onEmissionsCategoryChange={(category) =>
                setEmissionsCategory(category)
              }
              timelineYear={timelineYear}
              onTimelineChange={setTimelineYear}
            />
          </div>
        </div>

        <div className="mt-4 mb-6">
          <PerformanceNavbar />
        </div>

        <Suspense fallback={<Spinner isLoading />}>
          <GenericErrorBoundary>
            <Outlet />
          </GenericErrorBoundary>
        </Suspense>
      </GlobalFiltersContext.Provider>
    </PageContainer>
  );
}
