import { Purchase } from '@mammothclimate/mammoth_be/api/resources/internal';
import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetCompanyInvoices } from 'mc/api/endpoints/invoices';
import { useGetCompanyPurchases } from 'mc/api/endpoints/purchases';
import {
  DASHBOARD_ORDERS_NEW_ROUTE,
  DASHBOARD_ORDERS_ROUTE,
} from 'mc/app/routeUtils';
import PageContainer from 'mc/features/common/components/page-container';
import Spinner from 'mc/features/common/components/spinner';
import OrderAlertsList from 'mc/features/orders/components/order-alert-list';
import OrderTable from 'mc/features/orders/components/order-table';
import { PurchaseStatus, computeOrderStatuses } from 'mc/features/orders/utils';

import { Button } from '@/components/ui/button';

export type LineItemDelta = {
  qtyDelta: number;
  totalCostDelta: number;
  unitCostDelta: number;
};

export interface PurchaseWithInvoiceData extends Purchase {
  errorCount: number;
  status: PurchaseStatus;
  costDelta: number;
  lineItemDeltas: LineItemDelta[];
}

export default function OrdersIndexPage() {
  const navigate = useNavigate();

  const { data: companyPurchasesRaw } = useGetCompanyPurchases({});
  // HACK: purchases shouldn't be missing line items
  const companyPurchases = companyPurchasesRaw?.filter(
    (purchase) => purchase.lineItems.length > 0,
  );
  const { data: companyInvoices } = useGetCompanyInvoices({});
  const purchasesWithInvoiceData = computeOrderStatuses(
    companyPurchases,
    companyInvoices,
  );
  let errorPurchases: Purchase[] = [];
  let pendingPurchases: Purchase[] = [];

  if (companyPurchases && companyInvoices) {
    errorPurchases = purchasesWithInvoiceData.filter(
      (purchase) => purchase.status === PurchaseStatus.ERROR,
    );
    pendingPurchases = purchasesWithInvoiceData.filter(
      (purchase) => purchase.status === PurchaseStatus.PENDING,
    );
  }

  return (
    <Suspense fallback={<Spinner isLoading />}>
      <PageContainer>
        <div className="p-6 mt-10 rounded-md drop-shadow-sm bg-white">
          <div className="flex items-center justify-between">
            <div>
              <p className="font-medium">Start a new purchase order</p>
              <p className="text-xs text-gray-500">
                Place an order with a vendor
              </p>
            </div>
            <div>
              <Button
                className="text-xs"
                onClick={() => navigate(DASHBOARD_ORDERS_NEW_ROUTE)}
                type="button"
                variant="outline"
              >
                Create New Order
              </Button>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <div className="flex items-stretch -mx-2">
            {[PurchaseStatus.ERROR, PurchaseStatus.PENDING].map((status) => (
              <div
                key={status}
                className="w-1/2 mx-2 bg-white rounded-md drop-shadow-sm"
              >
                <OrderAlertsList
                  purchases={
                    status === PurchaseStatus.ERROR
                      ? errorPurchases
                      : pendingPurchases
                  }
                  status={status}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="mt-6">
          <OrderTable
            data={purchasesWithInvoiceData}
            onRowClick={(purchaseId) =>
              navigate(`${DASHBOARD_ORDERS_ROUTE}/${purchaseId}`)
            }
          />
        </div>
      </PageContainer>
    </Suspense>
  );
}
