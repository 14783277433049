import { useEffect } from 'react';
import iconsHtmlStr from './_icons.html?raw';

// TODO: eventually can inline in HTML to avoid FOUC,
// but only really relevant if we move to SSR
export default function IconSet() {
  useEffect(() => {
    // very dumb, but only allow one rendering of this component per app initialization
    const mammothIconsEl = window.document.getElementById('mammoth-icons');
    if (mammothIconsEl) {
      return;
    }

    const iconsDiv = window.document.createElement('div');
    iconsDiv.style.display = 'none';
    iconsDiv.innerHTML = iconsHtmlStr;
    window.document.body.prepend(iconsDiv);
  }, []);

  return null;
}
