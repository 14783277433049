import {
  CompanyInvoicesHttpRequest,
  Invoice,
  InvoiceId,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import { useQuery } from '@tanstack/react-query';

import useApiClient from '../hooks/use-api-client';

export const QUERY_KEY_PREFIX = 'invoices';

export function useGetCompanyInvoices(req: CompanyInvoicesHttpRequest) {
  const client = useApiClient();

  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEY_PREFIX, req],
    queryFn: () => client.internal.invoices.getCompanyInvoices(req),
  });

  // NOTE: sort data in descending order because there could be multiple invoices for purchases
  // so we just want to take the latest invoice when matching with a PO
  data?.sort((a: Invoice, b: Invoice) => {
    if (a?.creationDate > b?.creationDate) {
      return -1;
    }

    if (a?.creationDate < b?.creationDate) {
      return 1;
    }

    return 0;
  });

  return {
    ...rest,
    data,
  };
}

export function useGetInvoice(req: InvoiceId) {
  const client = useApiClient();

  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, req],
    queryFn: () => client.internal.invoices.getInvoice(req),
  });
}
