import { faker } from '@faker-js/faker';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { useNavigate } from 'react-router-dom';

import { Button } from 'mc/@/components/ui/button';
import {
  DASHBOARD_PLANNING_INSIGHTS_ROUTE,
  DASHBOARD_PLANNING_PERFORMANCE_ROUTE,
  DASHBOARD_PLANNING_SND_ROUTE,
} from 'mc/app/routeUtils';
import Icon from 'mc/features/common/components/icon';
import InformationTooltip from 'mc/features/common/components/information-tooltip';
import PageContainer from 'mc/features/common/components/page-container';
import { Section } from 'mc/features/common/components/section';
import { InsightList } from 'mc/features/planning/components/insight-list';
import { ProjectionTable } from 'mc/features/planning/components/projection-table';
import { LineChart } from 'mc/features/visualizations/components/line-chart';
import {
  MARGIN_LINE_CHART_DATA,
  mockInsights,
  productProjectionTableData,
} from 'mc/mocks/factories/insights';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

export interface OverviewPageHeroNumberProps {
  amount: string;
  description: string;
  title: string;
}

export function OverviewPageHeroNumber({
  amount,
  description,
  title,
}: OverviewPageHeroNumberProps) {
  return (
    <div>
      <div className="flex items-center justify-between">
        <p className="font-medium text-xs lg:text-base">{title}</p>
        <div className="ml-1">
          <InformationTooltip content={faker.lorem.sentences()} />
        </div>
      </div>
      <p className="mt-2 font-semibold text-5xl" aria-label={amount}>
        {amount}
      </p>
      <p className="mt-4">{description}</p>
      <p className="mt-2 text-gray-500">{faker.lorem.sentence(5)}</p>
    </div>
  );
}

export default function OverviewPage() {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <div>
        <div className="flex items-center justify-between">
          <h2 className="text-4xl font-semibold">Performance</h2>
          <Button
            onClick={() => navigate(DASHBOARD_PLANNING_PERFORMANCE_ROUTE)}
            type="button"
            variant="ghost"
          >
            Details
            <Icon className="ml-1" name="chevron-right" width="14px" />
          </Button>
        </div>
        <div className="mt-4 grid grid-cols-12 gap-8">
          <div className="col-span-9">
            <Section>
              <h3 className="text-lg font-semibold">Aggregated Margin</h3>
              <div className="mt-4">
                <LineChart
                  options={{ plugins: { legend: false } }}
                  data={MARGIN_LINE_CHART_DATA}
                />
              </div>
            </Section>
          </div>
          <div className="col-span-3">
            <div className="h-full flex flex-col justify-between">
              <Section>
                <OverviewPageHeroNumber
                  amount="$2.5 M"
                  title="Revenue"
                  description="Week 1 of Sept 2023"
                />
              </Section>
              <Section>
                <OverviewPageHeroNumber
                  amount="$1.8 M"
                  title="Cost"
                  description="Week 1 of Sept 2023"
                />
              </Section>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <div className="grid grid-cols-2 gap-8">
          <div className="col-span-1">
            <div className="flex items-center justify-between">
              <h2 className="text-4xl font-semibold">Insights</h2>
              <Button
                onClick={() => navigate(DASHBOARD_PLANNING_INSIGHTS_ROUTE)}
                type="button"
                variant="ghost"
              >
                Details
                <Icon className="ml-1" name="chevron-right" width="14px" />
              </Button>
            </div>

            <div className="mt-4">
              <Section>
                <InsightList insights={mockInsights} />
              </Section>
            </div>
          </div>
          <div className="col-span-1">
            <div className="flex items-center justify-between">
              <h2 className="text-4xl font-semibold">Supply & Demand</h2>
              <Button
                onClick={() => navigate(DASHBOARD_PLANNING_SND_ROUTE)}
                type="button"
                variant="ghost"
              >
                Details
                <Icon className="ml-1" name="chevron-right" width="14px" />
              </Button>
            </div>

            <div className="mt-4">
              <Section>
                <ProjectionTable
                  data={productProjectionTableData}
                  type="product"
                />
              </Section>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
