import PerformanceDriversSection from 'mc/features/performance/components/drivers-section';
import FunctionalUnitEmissionsSection from 'mc/features/performance/components/fu-emissions-section';
import PerformanceHeroNumbersSection from 'mc/features/performance/components/hero-numbers-section';

import { EmissionsLevel } from '@mammothclimate/mammoth_be/api/resources/internal';
import InputLifecycleSection from 'mc/features/performance/components/input-lifecycle-section';

export default function PortfolioShow() {
  return (
    <div>
      <div className="mt-6">
        <PerformanceHeroNumbersSection />
      </div>

      <div className="mt-6">
        <PerformanceDriversSection />
      </div>
      <div className="mt-6 bg-white rounded-lg drop-shadow-sm">
        <FunctionalUnitEmissionsSection />
      </div>

      <div className="mt-6 bg-white round-lg drop-shadow-sm">
        <InputLifecycleSection parentLevel={EmissionsLevel.Portfolio} />
      </div>
    </div>
  );
}
