export const HOME_ROUTE = '/';

export const DASHBOARD_ROUTE = '/dashboard';
export const DASHBOARD_PERFORMANCE_ROUTE = `${DASHBOARD_ROUTE}/performance`;
export const DASHBOARD_PERFORMANCE_PORTFOLIO_ROUTE = `${DASHBOARD_PERFORMANCE_ROUTE}/portfolio`;
export const DASHBOARD_PERFORMANCE_PRODUCTS_INDEX_ROUTE = `${DASHBOARD_PERFORMANCE_ROUTE}/products`;
export const DASHBOARD_PERFORMANCE_PRODUCTS_SHOW_ROUTE = `${DASHBOARD_PERFORMANCE_PRODUCTS_INDEX_ROUTE}/:fuId`;
export const DASHBOARD_PERFORMANCE_INPUTS_ROUTE = `${DASHBOARD_PERFORMANCE_ROUTE}/inputs`;
export const DASHBOARD_ITEMS_ROUTE = `${DASHBOARD_ROUTE}/items`;
export const DASHBOARD_ITEMS_INDEX_ROUTE = `${DASHBOARD_ROUTE}/items/all`;
export const DASHBOARD_ITEMS_SHOW_ROUTE = `${DASHBOARD_ITEMS_ROUTE}/:referenceId`;
export const DASHBOARD_ORDERS_ROUTE = `${DASHBOARD_ROUTE}/orders`;
export const DASHBOARD_ORDERS_INDEX_ROUTE = `${DASHBOARD_ROUTE}/orders/all`;
export const DASHBOARD_ORDERS_NEW_ROUTE = `${DASHBOARD_ROUTE}/orders/new`;
export const DASHBOARD_ORDERS_SHOW_ROUTE = `${DASHBOARD_ROUTE}/orders/:purchaseId`;
export const DASHBOARD_ORDERS_EDIT_ROUTE = `${DASHBOARD_ROUTE}/orders/:purchaseId/edit`;
export const DASHBOARD_PLANNING_ROUTE = `${DASHBOARD_ROUTE}/planning`;
export const DASHBOARD_PLANNING_OVERVIEW_ROUTE = `${DASHBOARD_PLANNING_ROUTE}/overview`;
export const DASHBOARD_PLANNING_PERFORMANCE_ROUTE = `${DASHBOARD_PLANNING_ROUTE}/performance`; // kind of confusing that there's a performance route AND a planning/performance route, but the former's probably going bye-bye
export const DASHBOARD_PLANNING_SND_ROUTE = `${DASHBOARD_PLANNING_ROUTE}/supply-and-demand`;
export const DASHBOARD_PLANNING_INSIGHTS_ROUTE = `${DASHBOARD_PLANNING_ROUTE}/insights`;

export const DASHBOARD_DATA_HUB_ROUTE = `${DASHBOARD_ROUTE}/data-hub`;
export const DASHBOARD_ACTIONS_ROUTE = `${DASHBOARD_ROUTE}/actions`;

// export const DASHBOARD_DEFAULT_ROUTE = DASHBOARD_ITEMS_ROUTE;
// export const DASHBOARD_DEFAULT_ROUTE = DASHBOARD_PLANNING_OVERVIEW_ROUTE;
export const DASHBOARD_DEFAULT_ROUTE = DASHBOARD_PLANNING_SND_ROUTE;

export const ACCOUNT_ROUTE = '/account';
export const ACCOUNT_LOGIN_ROUTE = `${ACCOUNT_ROUTE}/login`;
export const ACCOUNT_LOGIN_CALLBACK_ROUTE = `${ACCOUNT_ROUTE}/login-callback`;
export const ACCOUNT_SETTINGS_ROUTE = `${ACCOUNT_ROUTE}/settings`;
export const ACCOUNT_PROFILE_ROUTE = `${ACCOUNT_ROUTE}/profile`;

export const HELP_ROUTE = '/help';
