import { ErrorBoundary as HighlightErrorBoundary } from '@highlight-run/react';
import { KindeProvider } from '@kinde-oss/kinde-auth-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { H } from 'highlight.run';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import queryClient from './api/utils/query-client';
import App from './app/app';
import { ACCOUNT_LOGIN_CALLBACK_ROUTE } from './app/routeUtils';
import './features/common/utils/i18n';

if (import.meta.env.VITE_ENV !== 'local') {
  H.init('odz4xxgp', {
    environment: import.meta.env.VITE_ENV,
    tracingOrigins: [
      'localhost',
      '127.0.0.1',
      '0.0.0.0',
      `app.${import.meta.env.VITE_ENV}.mammothclimate.com/api`,
    ],
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        'https://www.googleapis.com/identitytoolkit',
        'https://securetoken.googleapis.com',
      ],
    },
    enableCanvasRecording: true,
    recordCrossOriginIframe: true,
    enableStrictPrivacy: true, // Obfuscates all text and images
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

function renderTree() {
  root.render(
    <HighlightErrorBoundary>
      <React.StrictMode>
        <Suspense>
          <KindeProvider
            audience={import.meta.env.VITE_AUTH_AUDIENCE}
            clientId={import.meta.env.VITE_AUTH_CLIENT_ID}
            domain={import.meta.env.VITE_AUTH_DOMAIN}
            logoutUri={window.location.origin}
            redirectUri={`${window.location.origin}${ACCOUNT_LOGIN_CALLBACK_ROUTE}`}
            isDangerouslyUseLocalStorage={import.meta.env.VITE_ENV === 'dev'}
          >
            <QueryClientProvider client={queryClient}>
              <App />

              {import.meta.env.VITE_ENV === 'local' && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
            </QueryClientProvider>
          </KindeProvider>
        </Suspense>
      </React.StrictMode>
    </HighlightErrorBoundary>,
  );
}

if (import.meta.env.VITE_USE_MSW) {
  const MSW_WHITELISTED_RE = new RegExp(
    [
      'chrome',
      'favicon',
      'fonts',
      'highlight',
      'image',
      'img',
      'kinde',
      'locales',
      'logo',
      'lorem',
      'oauth',
      'photos',
      'picsum',
      'placeholder',
      'src',

      // ignore all unhandled API endpoints -- should passthrough
      '/api',
    ].join('|'),
    'i',
  );

  import('./mocks/browser')
    .then(({ worker }) => {
      worker.start({
        onUnhandledRequest(req, print) {
          if (MSW_WHITELISTED_RE.test(req.url.href)) {
            return;
          }

          print.warning();
        },
      });
    })
    .then(renderTree);
} else {
  renderTree();
}
