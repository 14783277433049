import { Navigate, RouteObject } from 'react-router-dom';

import WithConversation from 'mc/layouts/with-conversation';
import WithPerformanceContext from 'mc/layouts/with-performance-context';
import LayoutWithSidebar from 'mc/layouts/with-sidebar';
import Login from 'mc/routes/account/login';
import LoginCallback from 'mc/routes/account/login-callback';
import Profile from 'mc/routes/account/profile';
import Settings from 'mc/routes/account/settings';
import ItemsIndexPage from 'mc/routes/dashboard/items';
import ItemsShowPage from 'mc/routes/dashboard/items/show';
import OrdersIndexPage from 'mc/routes/dashboard/orders';
import OrdersEditPage from 'mc/routes/dashboard/orders/edit';
import OrdersNewPage from 'mc/routes/dashboard/orders/new';
import OrdersShowPage from 'mc/routes/dashboard/orders/show';
import InputsIndex from 'mc/routes/dashboard/performance/inputs/index';
import PortfolioShow from 'mc/routes/dashboard/performance/portfolio/show';
import ProductsIndex from 'mc/routes/dashboard/performance/products/index';
import ProductsShow from 'mc/routes/dashboard/performance/products/show';
import InsightsPage from 'mc/routes/dashboard/planning/insights';
import OverviewPage from 'mc/routes/dashboard/planning/overview';
import PerformancePage from 'mc/routes/dashboard/planning/performance';
import SupplyAndDemandPage from 'mc/routes/dashboard/planning/snd';
import Error from 'mc/routes/error';
import ProtectedRoute from 'mc/routes/protected-route';
import {
  ACCOUNT_LOGIN_CALLBACK_ROUTE,
  ACCOUNT_LOGIN_ROUTE,
  ACCOUNT_PROFILE_ROUTE,
  ACCOUNT_ROUTE,
  ACCOUNT_SETTINGS_ROUTE,
  DASHBOARD_DEFAULT_ROUTE,
  DASHBOARD_ITEMS_INDEX_ROUTE,
  DASHBOARD_ITEMS_ROUTE,
  DASHBOARD_ITEMS_SHOW_ROUTE,
  DASHBOARD_ORDERS_EDIT_ROUTE,
  DASHBOARD_ORDERS_INDEX_ROUTE,
  DASHBOARD_ORDERS_NEW_ROUTE,
  DASHBOARD_ORDERS_ROUTE,
  DASHBOARD_ORDERS_SHOW_ROUTE,
  DASHBOARD_PERFORMANCE_INPUTS_ROUTE,
  DASHBOARD_PERFORMANCE_PORTFOLIO_ROUTE,
  DASHBOARD_PERFORMANCE_PRODUCTS_INDEX_ROUTE,
  DASHBOARD_PERFORMANCE_PRODUCTS_SHOW_ROUTE,
  DASHBOARD_PERFORMANCE_ROUTE,
  DASHBOARD_PLANNING_INSIGHTS_ROUTE,
  DASHBOARD_PLANNING_OVERVIEW_ROUTE,
  DASHBOARD_PLANNING_PERFORMANCE_ROUTE,
  DASHBOARD_PLANNING_ROUTE,
  DASHBOARD_PLANNING_SND_ROUTE,
  DASHBOARD_ROUTE,
  HELP_ROUTE,
} from './routeUtils';

export const dashboardRoutes: RouteObject[] = [
  {
    path: DASHBOARD_ROUTE,
    element: (
      <ProtectedRoute>
        <LayoutWithSidebar />
      </ProtectedRoute>
    ),
    errorElement: <Error />,
    children: [
      {
        path: DASHBOARD_PERFORMANCE_ROUTE,
        element: <WithPerformanceContext />,
        children: [
          {
            path: DASHBOARD_PERFORMANCE_PORTFOLIO_ROUTE,
            element: <PortfolioShow />,
          },
          {
            path: DASHBOARD_PERFORMANCE_PRODUCTS_INDEX_ROUTE,
            element: <ProductsIndex />,
          },
          {
            path: DASHBOARD_PERFORMANCE_PRODUCTS_SHOW_ROUTE,
            element: <ProductsShow />,
          },
          {
            path: DASHBOARD_PERFORMANCE_INPUTS_ROUTE,
            element: <InputsIndex />,
          },
          // catch-all
          {
            path: DASHBOARD_PERFORMANCE_ROUTE,
            element: <Navigate to={DASHBOARD_PERFORMANCE_PORTFOLIO_ROUTE} />,
          },
        ],
      },
      {
        path: DASHBOARD_ITEMS_ROUTE,
        children: [
          {
            path: DASHBOARD_ITEMS_INDEX_ROUTE,
            element: <ItemsIndexPage />,
          },
          {
            path: DASHBOARD_ITEMS_SHOW_ROUTE,
            element: <ItemsShowPage />,
          },
          // catch-all
          {
            path: DASHBOARD_ITEMS_ROUTE,
            element: <Navigate to={DASHBOARD_ITEMS_INDEX_ROUTE} />,
          },
        ],
      },
      {
        path: DASHBOARD_ORDERS_INDEX_ROUTE,
        element: <OrdersIndexPage />,
      },
      {
        path: DASHBOARD_ORDERS_ROUTE,
        element: <WithConversation />,
        children: [
          {
            path: DASHBOARD_ORDERS_EDIT_ROUTE,
            element: <OrdersEditPage />,
          },
          {
            path: DASHBOARD_ORDERS_SHOW_ROUTE,
            element: <OrdersShowPage />,
          },
          {
            path: DASHBOARD_ORDERS_NEW_ROUTE,
            element: <OrdersNewPage />,
          },
          // catch-all
          {
            path: DASHBOARD_ORDERS_ROUTE,
            element: <Navigate to={DASHBOARD_ORDERS_INDEX_ROUTE} />,
          },
        ],
      },
      {
        path: DASHBOARD_PLANNING_ROUTE,
        children: [
          {
            path: DASHBOARD_PLANNING_OVERVIEW_ROUTE,
            element: <OverviewPage />,
          },
          {
            path: DASHBOARD_PLANNING_PERFORMANCE_ROUTE,
            element: <PerformancePage />,
          },
          {
            path: DASHBOARD_PLANNING_SND_ROUTE,
            element: <SupplyAndDemandPage />,
          },
          {
            path: DASHBOARD_PLANNING_INSIGHTS_ROUTE,
            element: <InsightsPage />,
          },
          // catch-all
          {
            path: DASHBOARD_PLANNING_ROUTE,
            element: <Navigate to={DASHBOARD_PLANNING_OVERVIEW_ROUTE} />,
          },
        ],
      },
    ],
  },
];

export const accountRoutes: RouteObject[] = [
  {
    path: ACCOUNT_ROUTE,
    element: (
      <ProtectedRoute>
        <LayoutWithSidebar />
      </ProtectedRoute>
    ),
    children: [
      {
        path: ACCOUNT_SETTINGS_ROUTE,
        element: <Settings />,
      },
      {
        path: ACCOUNT_PROFILE_ROUTE,
        element: <Profile />,
      },
    ],
  },
  {
    path: ACCOUNT_LOGIN_ROUTE,
    element: <Login />,
  },
  {
    path: ACCOUNT_LOGIN_CALLBACK_ROUTE,
    element: <LoginCallback />,
  },
];

export const routes: RouteObject[] = [
  ...accountRoutes,

  ...dashboardRoutes,

  {
    path: HELP_ROUTE,
    element: <div>Help</div>,
  },

  // catch-alls
  {
    path: '*',
    element: <Navigate to={DASHBOARD_DEFAULT_ROUTE} />,
  },
];
