import { CompanyContractsHttpRequest } from '@mammothclimate/mammoth_be/api/resources/internal';
import { useQuery } from '@tanstack/react-query';

import useApiClient from '../hooks/use-api-client';

const QUERY_KEY_PREFIX = 'contracts';

export function useGetCompanyContracts(req: CompanyContractsHttpRequest) {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, req],
    queryFn: () => apiClient.internal.contracts.getCompanyContracts(req),
  });
}
