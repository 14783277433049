import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import {
  ACCOUNT_PROFILE_ROUTE,
  ACCOUNT_SETTINGS_ROUTE,
  DASHBOARD_ITEMS_ROUTE,
  DASHBOARD_ORDERS_ROUTE,
  DASHBOARD_PERFORMANCE_PORTFOLIO_ROUTE,
  DASHBOARD_PLANNING_INSIGHTS_ROUTE,
  DASHBOARD_PLANNING_OVERVIEW_ROUTE,
  DASHBOARD_PLANNING_PERFORMANCE_ROUTE,
  DASHBOARD_PLANNING_SND_ROUTE,
  HELP_ROUTE,
  HOME_ROUTE,
} from 'mc/app/routeUtils';
import logoPng from 'mc/assets/logo.png';
import Icon from 'mc/features/common/components/icon';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { cn } from '@/lib/utils';

export interface SidebarNavLinkContentsProps {
  iconName: string;
  isActive?: boolean;
  isLocked?: boolean;
  isSubRoute?: boolean;
  title: string;
}

export function SidebarNavLinkContents({
  iconName,
  isActive,
  isLocked = false,
  isSubRoute = false,
  title,
}: SidebarNavLinkContentsProps) {
  return (
    <div
      className={cn('py-3 flex', {
        'px-6': !isSubRoute,
        'px-10': isSubRoute,
      })}
    >
      <div
        className={cn('h-full border-r-4 rounded-xl absolute left-0 top-0', {
          'border-r-transparent': !isActive,
          'border-r-black': isActive,
        })}
      />
      <Icon name={iconName} />
      <p className="ml-4">{title}</p>
      {isLocked && (
        <div className="absolute top-0 right-0 translate-y-2/4">
          <div className="p-1 border rounded-full">
            <Icon className="text-slate-500" width="12px" name="lock" />
          </div>
        </div>
      )}
    </div>
  );
}

export interface SidebarNavLinkProps {
  iconName: string;
  isLocked?: boolean;
  isSubRoute?: boolean;
  title: string;
  to: string;
}

export function SidebarNavLink({
  iconName,
  isLocked = false,
  isSubRoute = false,
  title,
  to,
}: SidebarNavLinkProps) {
  const { t } = useTranslation();

  return (
    <NavLink
      className={({ isActive }) =>
        cn(
          'flex items-center relative rounded-lg dark:text-white font-medium',
          {
            'font-semibold text-gray-900': isActive,
            'text-gray-500': !isActive,
            'hover:text-gray-900': !isLocked,
            'cursor-not-allowed': isLocked,
          },
        )
      }
      to={to}
      onClick={isLocked ? (e) => e.preventDefault() : undefined}
      title={isLocked ? t('under_construction', { ns: 'errors' }) : undefined}
    >
      {({ isActive }) => (
        <SidebarNavLinkContents
          iconName={iconName}
          isActive={isActive}
          isLocked={isLocked}
          isSubRoute={isSubRoute}
          title={title}
        />
      )}
    </NavLink>
  );
}

type SidebarRouteDefinition = {
  to?: string;
  title: string;
  iconName: string;
  isLocked?: boolean;
  routes?: SidebarRouteDefinition[];
};

const dashboardRouteDefinitions: SidebarRouteDefinition[] = [
  {
    to: DASHBOARD_PERFORMANCE_PORTFOLIO_ROUTE,
    title: 'Performance',
    iconName: 'chart',
  },
  // {
  //   to: DASHBOARD_DATA_HUB_ROUTE,
  //   title: 'Data Hub',
  //   iconName: 'element-plus',
  //   isLocked: true
  // },
  {
    to: DASHBOARD_ORDERS_ROUTE,
    title: 'Orders',
    iconName: 'element-plus',
  },
  // {
  //   to: DASHBOARD_ACTIONS_ROUTE,
  //   title: 'Actions',
  //   iconName: "briefcase",
  //   isLocked: true
  // },
  {
    to: DASHBOARD_ITEMS_ROUTE,
    title: 'Items',
    iconName: 'briefcase',
  },
  {
    title: 'Planning',
    iconName: 'chart',
    routes: [
      {
        to: DASHBOARD_PLANNING_OVERVIEW_ROUTE,
        title: 'Overview',
        iconName: 'chart',
      },
      {
        to: DASHBOARD_PLANNING_PERFORMANCE_ROUTE,
        title: 'Performance',
        iconName: 'chart',
      },
      {
        to: DASHBOARD_PLANNING_SND_ROUTE,
        title: 'Supply & Demand',
        iconName: 'chart',
      },
      {
        to: DASHBOARD_PLANNING_INSIGHTS_ROUTE,
        title: 'Insights',
        iconName: 'chart',
      },
    ],
  },
];

const accountRouteDefinitions: SidebarRouteDefinition[] = [
  {
    to: ACCOUNT_PROFILE_ROUTE,
    title: 'Profile',
    iconName: 'user',
    isLocked: true,
  },
  {
    to: ACCOUNT_SETTINGS_ROUTE,
    title: 'Settings',
    iconName: 'gear',
    isLocked: true,
  },
  {
    to: HELP_ROUTE,
    title: 'Get Help',
    iconName: 'question',
    isLocked: true,
  },
];

const allRouteGroups = [dashboardRouteDefinitions, accountRouteDefinitions];

export default function Sidebar() {
  const [accordionValue, setAccordionValue] = useState('planning');
  return (
    <nav className="Sidebar w-full pt-10 text-black bg-white">
      <NavLink className="px-6 block" to={HOME_ROUTE}>
        <img
          className="w-[119px] h-[18px]"
          src={logoPng}
          alt="mammoth climate logo"
        />
      </NavLink>

      <div className="pr-6">
        {allRouteGroups.map((routeGroup, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <ul key={idx} className="pr-6 mt-10">
            {routeGroup.map(({ routes, ...rest }) => {
              if (routes) {
                // if this has subroutes, render them in an accordion
                // since this isn't a route on its own
                return (
                  <li key={rest.title}>
                    <Accordion
                      value={accordionValue}
                      onValueChange={(_accordionValue) =>
                        setAccordionValue(_accordionValue)
                      }
                      className="py-0"
                      type="single"
                      collapsible
                    >
                      <AccordionItem value="planning" className="border-0">
                        <AccordionTrigger className="py-0 border-0 text-base dark:text-white hover:no-underline text-gray-900 hover:text-gray-900">
                          <SidebarNavLinkContents {...rest} />
                        </AccordionTrigger>
                        <AccordionContent>
                          <ul>
                            {routes.map((route) => (
                              <li className="text-base" key={route.title}>
                                <SidebarNavLink
                                  {...route}
                                  to={route.to!!}
                                  isSubRoute
                                />
                              </li>
                            ))}
                          </ul>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </li>
                );
              }

              return (
                <li key={rest.title}>
                  <SidebarNavLink {...rest} to={rest.to!!} />
                </li>
              );
            })}
          </ul>
        ))}
      </div>
    </nav>
  );
}
