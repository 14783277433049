export function getInitials(name: string) {
  return name
    .split(' ')
    .map((n) => n[0])
    .join('');
}

// yyyy-mm-dd
export function formatDate(date: string | Date) {
  let finalDate: Date;

  if (typeof date === 'string') {
    finalDate = new Date(date);
  } else {
    finalDate = date;
  }

  const offset = finalDate.getTimezoneOffset();
  finalDate = new Date(finalDate.getTime() - offset * 60 * 1000);
  return finalDate.toISOString().split('T')[0];
}

export function getStartOfDate(date: Date) {
  const startDate = new Date(date);
  startDate.setHours(0, 0, 0, 0);
  return startDate;
}

// amount of time to refetch resources
export const REFETCH_INTERVAL_TIME = 5000;
