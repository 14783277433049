import {
  CategoryComparisonRequest,
  EmissionsDriversRequest,
  EmissionsLifecycleStageBreakdownHttpRequest,
  EmissionsListRequest,
  ProductEmissionsRequest,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import { useQuery } from '@tanstack/react-query';

import useApiClient from '../hooks/use-api-client';

export const QUERY_KEY_PREFIX = 'emissions';

export function useGetFunctionalUnitEmissions(req: ProductEmissionsRequest) {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, 'functionalUnit', req],
    queryFn: () => apiClient.internal.emissions.getFunctionalUnitEmissions(req),
  });
}

export function useGetEmissionsList(req: EmissionsListRequest) {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, 'list', req],
    queryFn: () => apiClient.internal.emissions.getEmissionsList(req),
  });
}

export function useGetDriversList(req: EmissionsDriversRequest) {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, 'drivers', req],
    queryFn: () => apiClient.internal.emissions.getDriversList(req),
  });
}

export function useGetCategoryComparison(req: CategoryComparisonRequest) {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: ['emissions', 'categoryComparison', req],
    queryFn: () => apiClient.internal.emissions.getCategoryComparison(req),
  });
}

export function useGetLifecycleStageBreakdown(
  req: EmissionsLifecycleStageBreakdownHttpRequest,
) {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, 'lcs', req],
    queryFn: () => apiClient.internal.emissions.getLifecycleStageBreakdown(req),
  });
}
