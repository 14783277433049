import {
  CreateConversationMessageHttpRequest,
  GetConversationHttpRequest,
  GetTopicConversationHttpRequest,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import { useMutation, useQuery } from '@tanstack/react-query';

import useApiClient from '../hooks/use-api-client';

export const QUERY_KEY_PREFIX = 'conversations';

export function useGetConversation(req: GetConversationHttpRequest) {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, req],
    queryFn: () => apiClient.internal.conversations.getConversation(req),
  });
}

export function useGetTopicConversation(req: GetTopicConversationHttpRequest) {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, req],
    queryFn: () => apiClient.internal.conversations.getTopicConversation(req),
  });
}

export function useCreateConversationMessage() {
  const apiClient = useApiClient();

  return useMutation({
    mutationKey: [QUERY_KEY_PREFIX, 'createConversationMessage'],
    mutationFn: (req: CreateConversationMessageHttpRequest) =>
      apiClient.internal.conversations.sendMessage(req),
  });
}
