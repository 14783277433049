import {
  Company,
  CreatePurchaseRequest,
  Currency,
  Purchase,
  Unit,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import { Reference } from '@mammothclimate/mammoth_be/api/resources/reference';
import * as _ from 'lodash-es';
import { useGetCompanies } from 'mc/api/endpoints/companies';
import { useGetCompanyContracts } from 'mc/api/endpoints/contracts';
import { useGetReferences } from 'mc/api/endpoints/references';
import { DASHBOARD_ORDERS_INDEX_ROUTE } from 'mc/app/routeUtils';
import { formatDate } from 'mc/features/common/utils';
import { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

export interface OrderEditFormInputProps extends Partial<HTMLInputElement> {
  label: string;
  name: string;
}

export function OrderEditFormInput({
  disabled,
  label,
  name,
  placeholder,
  type = 'number',
  value,
}: OrderEditFormInputProps) {
  const { control } = useFormContext();

  return (
    <div className="w-[180px]">
      <FormField
        control={control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-gray-500">{label}</FormLabel>
            <FormControl>
              <Input
                {...field}
                placeholder={placeholder}
                type={type}
                disabled={disabled}
                value={value || field.value}
              />
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  );
}

export type SelectOption = {
  title: string;
  value: string;
};

export interface OrderEditFormSelectProps extends Partial<HTMLSelectElement> {
  label: string;
  name: string;
  placeholder: string;
  selectOptions: SelectOption[];
}

export function OrderEditFormSelect({
  label,
  name,
  placeholder,
  selectOptions,
}: OrderEditFormSelectProps) {
  const { control } = useFormContext();

  return (
    <div className="w-[352px]">
      <FormField
        control={control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-gray-500">{label}</FormLabel>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <SelectTrigger>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
              <SelectContent>
                {selectOptions.map(({ title, value }) => (
                  <SelectItem key={value} value={value}>
                    {title}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        )}
      />
    </div>
  );
}

export interface OrdersEditFormProps {
  purchase?: Purchase; // optional, not provided if creating a new PO
  onSubmit: (data: CreatePurchaseRequest) => void;
}

export default function OrderEditForm({
  purchase,
  onSubmit,
}: OrdersEditFormProps) {
  const [total, setTotal] = useState(0);

  const { data: contractsData } = useGetCompanyContracts({});
  const vendorsData = useGetCompanies(
    _.uniq(contractsData?.map(({ sellingCompany }) => sellingCompany)) || [],
  ).map(({ data }) => data) as Company[];
  const referencesData = useGetReferences(
    _.uniq(contractsData?.map(({ referenceId }) => referenceId)) || [],
  ).map(({ data }) => data) as Reference[];

  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      externalId: purchase?.externalId || `${new Date().getTime()}`,
      lineItems: [
        {
          referenceId: searchParams.get('referenceId') || '',
          qty: 0,
          unitOfMeasurement: Unit.Kg,
          unitCost: parseFloat(searchParams.get('unitCost') || '0') || 0,
          totalCost: 0,
        },
      ],
      purchasingCompany: 'LnDNOO6FUqlZ2sYQ2lbPW', // HACK
      sellingCompany:
        searchParams.get('sellingCompany') || purchase?.sellingCompany || '',
      creationDate: formatDate(new Date()),
      dueDate: formatDate(new Date()),
      cost: 0,
      currency: Currency.Usd,
    },
  });
  const { handleSubmit, watch } = form;
  const qty = watch('lineItems.0.qty');
  const unitCost = watch('lineItems.0.unitCost');

  useEffect(() => {
    if (qty && unitCost) {
      setTotal(qty * unitCost);
    }
  }, [qty, unitCost]);

  return (
    <div className="p-6 bg-white rounded-md drop-shadow-sm">
      <Form {...form}>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <p className="text-2xl font-medium">Order details</p>

          <fieldset className="mt-5">
            <OrderEditFormInput
              name="externalId"
              label="PO#"
              required
              type="text"
            />

            <div className="mt-2">
              <OrderEditFormSelect
                name="sellingCompany"
                label="Vendor"
                placeholder="Select a vendor"
                selectOptions={vendorsData.map(({ id, name }) => ({
                  title: name,
                  value: id,
                }))}
              />
            </div>

            <div className="mt-2">
              <OrderEditFormSelect
                name="lineItems.0.referenceId"
                label="Item"
                placeholder="Select an item"
                selectOptions={referencesData.map(({ displayValue, id }) => ({
                  title: displayValue,
                  value: id,
                }))}
              />
            </div>

            <div className="mt-2">
              <OrderEditFormInput
                name="lineItems.0.qty"
                label="Quantity"
                required
                type="number"
                defaultValue={`${qty}`}
              />
            </div>

            <div className="mt-2">
              <OrderEditFormInput
                name="lineItems.0.unitCost"
                label="Price/Kg"
                required
                type="number"
                defaultValue={`${unitCost}`}
              />
            </div>

            <div className="mt-2">
              <OrderEditFormInput
                name="totalCost"
                label="Amount"
                required
                type="number"
                disabled
                value={`${total}`}
              />
            </div>

            <div className="mt-2">
              <OrderEditFormInput
                name="creationDate"
                label="Order Date"
                required
                type="date"
              />
            </div>

            <div className="mt-2">
              <OrderEditFormInput
                name="dueDate"
                label="Due Date"
                required
                type="date"
              />
            </div>
          </fieldset>

          <div className="mt-8 border-t py-4 flex justify-between">
            <p className="font-medium">Order total</p>
            <p className="font-medium text-green-500">
              {t('formats.currency.two_decimal', { value: total })}
            </p>
          </div>

          <hr className="-mx-6" />

          <div className="pt-6 pb-2 bt">
            <div className="-mx-2 flex justify-end">
              <Button
                size="lg"
                className="mx-2 text-sm font-normal"
                variant="outline"
                type="button"
                onClick={() => navigate(DASHBOARD_ORDERS_INDEX_ROUTE)}
              >
                Cancel
              </Button>
              <Button
                size="lg"
                className="mx-2 text-sm font-normal"
                variant="outline"
              >
                Create Order
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}
