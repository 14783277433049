import { cn } from '@/lib/utils';

export interface IconProps {
  className?: string;
  name: string; // TODO: infer from all icon names once inlined
  width?: string;
  height?: string;
}

export default function Icon({
  className,
  name,
  width = '24px',
  height,
}: IconProps) {
  // only width can be passed in for equilateral icons
  const trueHeight = height || width;

  return (
    <svg
      className={cn('block', className)}
      style={{
        height: trueHeight,
        minHeight: trueHeight,
        minWidth: width,
        width,
      }}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <use xlinkHref={`#icon-${name}`} />
    </svg>
  );
}
