import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import {
  MammothclimateMammothBeApiClient,
  MammothclimateMammothBeApiEnvironment,
} from '@mammothclimate/mammoth_be';

let apiClient: MammothclimateMammothBeApiClient;

export default function useApiClient() {
  const { getToken } = useKindeAuth();

  if (!apiClient) {
    apiClient = new MammothclimateMammothBeApiClient({
      token: async () => {
        try {
          const token = await getToken();
          return token || '';
        } catch (e) {
          return '';
        }
      },
      environment: (() => {
        if (import.meta.env.VITE_ENV === 'local') {
          return MammothclimateMammothBeApiEnvironment.Local;
        }
        if (import.meta.env.VITE_ENV === 'dev') {
          return MammothclimateMammothBeApiEnvironment.Development;
        }
        if (import.meta.env.VITE_ENV === 'staging') {
          return MammothclimateMammothBeApiEnvironment.Staging;
        }

        return MammothclimateMammothBeApiEnvironment.Production;
      })(),
    });
  }

  return apiClient;
}
