import {
  EmissionsLevel,
  EmissionsListSort,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetEmissionsList } from 'mc/api/endpoints/emissions';
import { useGetCompanyFunctionalUnits } from 'mc/api/endpoints/functional-units';
import FuTable, {
  FuTableRow,
} from 'mc/features/visualizations/components/fu-table';

import useOrganizationId from 'mc/api/hooks/use-organization-id';
import { DASHBOARD_PERFORMANCE_PRODUCTS_INDEX_ROUTE } from 'mc/app/routeUtils';
import {
  getTimelineFromYear,
  transformFuEmissionsToFuRows,
} from 'mc/features/performance/utils';
import { GlobalFiltersContext } from 'mc/features/performance/utils/context';

export default function ProductsIndex() {
  const { emissionsCategory, timelineYear } = useContext(GlobalFiltersContext);
  const [sort, setSort] = useState<EmissionsListSort[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const organizationId = useOrganizationId();

  const { data: productEmissionsData } = useGetEmissionsList({
    companyId: organizationId,
    sort,
    level: EmissionsLevel.FunctionalUnit,
    parentLevel: EmissionsLevel.Portfolio,
    category: emissionsCategory,
    timeline: getTimelineFromYear(timelineYear),
  });

  const { data: organizationFusData } = useGetCompanyFunctionalUnits({
    id: organizationId,
    timeline: getTimelineFromYear(timelineYear),
  });

  let FuTableRows: FuTableRow[] = [];

  if (productEmissionsData) {
    FuTableRows = transformFuEmissionsToFuRows(
      productEmissionsData[0].childEmissions,
      organizationFusData || [],
    );
  }

  return (
    <div className="bg-white rounded-lg drop-shadow-sm">
      <div className="py-8 px-6 flex justify-between">
        <h3 className="text-2xl font-semibold">
          {t('counts.product.count_many', {
            count: FuTableRows.length,
            ns: 'global',
          })}
        </h3>
      </div>
      <hr />
      <FuTable
        data={FuTableRows}
        emissionsCategory={emissionsCategory}
        onRowClick={(productId) => {
          navigate(
            `${DASHBOARD_PERFORMANCE_PRODUCTS_INDEX_ROUTE}/${productId}`,
          );
        }}
        onSortClick={(sortCategory) => setSort([sortCategory])}
      />
    </div>
  );
}
