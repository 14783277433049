import { cn } from 'mc/@/lib/utils';
import { ReactNode } from 'react';

export interface PageContainerProps {
  children?: ReactNode;
  className?: string;
}

export default function PageContainer({
  children,
  className,
}: PageContainerProps) {
  return <div className={cn('p-10', className)}>{children}</div>;
}
