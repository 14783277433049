import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useEffect } from 'react';

export default function Login() {
  const { login } = useKindeAuth();

  useEffect(() => {
    login({});
  }, [login]);

  return null;
}
