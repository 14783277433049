import { useState } from 'react';

import { faker } from '@faker-js/faker';
import { Tabs, TabsList, TabsTrigger } from 'mc/@/components/ui/tabs';
import PageContainer from 'mc/features/common/components/page-container';
import { Section } from 'mc/features/common/components/section';
import { ProjectionTable } from 'mc/features/planning/components/projection-table';
import DoughnutChart from 'mc/features/visualizations/components/doughnut-chart';
import { LineChart } from 'mc/features/visualizations/components/line-chart';
import {
  ENVIRONMENTAL_LINE_CHART_DATA,
  FINANCIAL_LINE_CHART_DATA,
  productDoughnutChartArcData,
  productProjectionTableData,
} from 'mc/mocks/factories/insights';

export type PerformanceToggleState = 'financial' | 'environmental';

export default function PerformancePage() {
  const [toggleValue, setToggleValue] =
    useState<PerformanceToggleState>('financial');
  const isFinanceToggled = toggleValue === 'financial';

  return (
    <PageContainer>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <h2 className="text-4xl font-semibold">Performance</h2>
        </div>
        <div>
          <Tabs
            defaultValue="financial"
            value={toggleValue}
            onValueChange={(_toggleValue) =>
              setToggleValue(_toggleValue as PerformanceToggleState)
            }
          >
            <TabsList className="bg-gray-100">
              <TabsTrigger value="financial">Finance</TabsTrigger>
              <TabsTrigger value="environmental">Sustainability</TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>

      <div className="mt-4">
        <Section>
          <div className="grid grid-rows-2 grid-flow-col gap-4 items-center">
            <div className="row-span-2">
              <h2 className="text-lg font-semibold">
                {isFinanceToggled ? 'Finance' : 'Sustainability'} Overview
              </h2>
              <div className="mt-4">
                <LineChart
                  data={
                    toggleValue === 'financial'
                      ? FINANCIAL_LINE_CHART_DATA
                      : ENVIRONMENTAL_LINE_CHART_DATA
                  }
                />
              </div>
            </div>
          </div>
        </Section>
      </div>

      <div className="mt-8">
        <div className="grid grid-cols-2 grid-flow-row gap-8">
          <div className="col-span-1">
            <Section>
              <h2 className="text-lg font-semibold">Product Overview</h2>

              <ProjectionTable
                data={productProjectionTableData}
                type="product"
              />
            </Section>
          </div>
          <div className="col-span-1">
            <Section>
              <div className="max-w-[400px] m-auto">
                <DoughnutChart
                  amount=""
                  subtitle=""
                  arcs={productDoughnutChartArcData}
                  onArcClick={() => {}}
                />
              </div>
              <div className="mt-2 text-center">
                <h4 className="text-xl font-semibold">
                  {faker.lorem.sentence()}
                </h4>
                <p className="mt-2 font-medium">{faker.lorem.paragraph()}</p>
                <div className="mt-6">
                  {productDoughnutChartArcData.map((arc) => (
                    <div
                      key={arc.key}
                      className="mr-4 mb-2 inline-flex items-center text-gray-500"
                    >
                      <div
                        className="w-1 h-1 p-1 rounded-full"
                        style={{ backgroundColor: arc.color }}
                      />
                      <p className="ml-2 font-medium text-xs">
                        {arc.name} {arc.percentOfTotal}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </Section>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
