import { ReactNode } from 'react';

import Icon from 'mc/features/common/components/icon';

import { cn } from '@/lib/utils';

export interface PerformanceDriverCardProps {
  content: ReactNode;
  iconVariant: 'error' | 'success';
  title: string;
  // tooltipContent: string;
}

export default function PerformanceDriverCard({
  content,
  iconVariant,
  title,
}: // tooltipContent,
PerformanceDriverCardProps) {
  return (
    <div className="h-full p-6 border rounded-lg drop-shadow-sm bg-white">
      <div className="flex">
        <div
          className={cn('p-3 rounded-full self-start', {
            'bg-red-500': iconVariant === 'error',
            'bg-green-500': iconVariant === 'success',
          })}
        >
          <Icon width="18px" name="checkmark" className="text-white" />
        </div>
        <div className="ml-4 font-medium">
          <p>{title}</p>
          <p className="text-sm">{content}</p>
        </div>
        {/* <div className="ml-2">
          <InformationTooltip content={tooltipContent} />
        </div> */}
      </div>
    </div>
  );
}
