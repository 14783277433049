import { ReactNode } from 'react';

import Icon from 'mc/features/common/components/icon';

import {
  Tooltip,
  TooltipContent,
  TooltipPrimitive,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

export interface InformationTooltipProps {
  content: ReactNode;
  iconClassName?: string;
  iconHeight?: string;
  iconWidth?: string;
}

export default function InformationTooltip({
  content,
  iconClassName,
  iconHeight,
  iconWidth,
}: InformationTooltipProps) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="cursor-pointer">
            <Icon
              width={iconWidth}
              height={iconHeight}
              className={cn('text-slate-500', iconClassName)}
              name="information"
            />
          </span>
        </TooltipTrigger>
        <TooltipContent
          className={cn('bg-white border text-black', {
            [`max-w-[200px]`]: true,
          })}
          sideOffset={20}
        >
          {content}
          <TooltipPrimitive.Arrow className="drop-shadow-sm fill-white" />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
