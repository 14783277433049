import { faker } from '@faker-js/faker';
import { ScriptableChartContext, ScriptableLineSegmentContext } from 'chart.js';
import { Factory } from 'fishery';
import {
  ProjectionTableRow,
  ProjectionTableType,
} from 'mc/features/planning/components/projection-table';
import { DoughutChartArcData } from 'mc/features/visualizations/components/doughnut-chart';
import {
  getGradientFillBackgroundColor,
  getSegmentBorderDash,
} from 'mc/features/visualizations/utils/chart';

export enum InsightGroup {
  ContractDepletion = 'CONTRACT_DEPLETION',
  Throughput = 'THROUGHPUT',
  LeadTime = 'LEAD_TIME',
}

export const InsightGroupTitles = {
  [InsightGroup.ContractDepletion]: 'Contract Depletion',
  [InsightGroup.Throughput]: 'Throughput',
  [InsightGroup.LeadTime]: 'Lead Time',
};

export interface Insight {
  id: string;
  metric: number;
  metricDetails: string;
  baseObject: string; // TODO
  group: InsightGroup; // TODO
}

export const InsightFactory = Factory.define<Insight>(() => ({
  id: faker.string.uuid(),
  metric: faker.number.float({ min: 1.0, max: 100.0, precision: 0.1 }),
  metricDetails: faker.lorem.sentence(),
  baseObject: faker.helpers.arrayElement(['contract-id']),
  group: faker.helpers.arrayElement([
    InsightGroup.ContractDepletion,
    InsightGroup.Throughput,
    InsightGroup.LeadTime,
  ]),
}));

export const mockInsights = InsightFactory.buildList(10);

// MOCK S&OP DATA
export const PROJECTION_LINE_SEGMENT = [10, 5];
export const WEEKS = Array.from({ length: 4 }).map(
  (_, idx) => `Week ${idx + 1}`,
);
export const MARGIN_LINE_CHART_DATA = {
  labels: WEEKS,
  datasets: [
    {
      label: 'Margin',
      data: WEEKS.map(() => faker.number.int({ min: 0, max: 800 })),
      borderColor: 'rgba(243, 145, 55, 1)',
      segment: {
        borderDash: (context: ScriptableLineSegmentContext) =>
          getSegmentBorderDash(context),
      },
      backgroundColor: (context: ScriptableChartContext) =>
        getGradientFillBackgroundColor(context, 'rgba(243, 145, 55, 0.3)'),
      fill: true,
      radius: 1,
      hoverRadius: 10,
      hitRadius: 100,
    },
  ],
};
export const FINANCIAL_LINE_CHART_DATA = {
  labels: WEEKS,
  datasets: [
    {
      label: 'Revenue',
      data: WEEKS.map(() => faker.number.int({ min: 0, max: 200 })),
      borderColor: 'rgba(12, 175, 96, 1)',
      segment: {
        borderDash: (context: ScriptableLineSegmentContext) =>
          getSegmentBorderDash(context),
      },
      backgroundColor: (context: ScriptableChartContext) =>
        getGradientFillBackgroundColor(context, 'rgba(12, 175, 96, 0.5)'),
      fill: true,
      radius: 2,
      hoverRadius: 10,
      hitRadius: 100,
    },
    {
      label: 'Cost',
      data: WEEKS.map(() => faker.number.int({ min: 0, max: 400 })),
      borderColor: 'rgba(253, 106, 106, 1)',
      segment: {
        borderDash: (context: ScriptableLineSegmentContext) =>
          getSegmentBorderDash(context),
      },
      backgroundColor: (context: ScriptableChartContext) =>
        getGradientFillBackgroundColor(context, 'rgba(253, 106, 106, 0.5)'),
      fill: true,
      radius: 2,
      hoverRadius: 10,
      hitRadius: 100,
    },
    {
      label: 'Margin',
      data: WEEKS.map(() => faker.number.int({ min: 0, max: 800 })),
      borderColor: 'rgba(243, 145, 55, 1)',
      segment: {
        borderDash: (context: ScriptableLineSegmentContext) =>
          getSegmentBorderDash(context),
      },
      backgroundColor: (context: ScriptableChartContext) =>
        getGradientFillBackgroundColor(context, 'rgba(243, 145, 55, 0.5)'),
      fill: true,
      radius: 1,
      hoverRadius: 10,
      hitRadius: 100,
    },
  ],
};
export const ENVIRONMENTAL_LINE_CHART_DATA = {
  labels: WEEKS,
  datasets: [
    {
      label: 'GHG',
      data: WEEKS.map(() => faker.number.int({ min: 0, max: 200 })),
      borderColor: 'rgba(12, 175, 96, 1)',
      segment: {
        borderDash: (context: ScriptableLineSegmentContext) =>
          getSegmentBorderDash(context),
      },
      backgroundColor: (context: ScriptableChartContext) =>
        getGradientFillBackgroundColor(context, 'rgba(12, 175, 96, 0.5)'),
      fill: true,
      radius: 2,
      hoverRadius: 10,
      hitRadius: 100,
    },
    {
      label: 'Land',
      data: WEEKS.map(() => faker.number.int({ min: 0, max: 400 })),
      borderColor: 'rgba(253, 106, 106, 1)',
      segment: {
        borderDash: (context: ScriptableLineSegmentContext) =>
          getSegmentBorderDash(context),
      },
      backgroundColor: (context: ScriptableChartContext) =>
        getGradientFillBackgroundColor(context, 'rgba(253, 106, 106, 0.5)'),
      fill: true,
      radius: 2,
      hoverRadius: 10,
      hitRadius: 100,
    },
    {
      label: 'Water',
      data: WEEKS.map(() => faker.number.int({ min: 0, max: 800 })),
      borderColor: 'rgba(243, 145, 55, 1)',
      segment: {
        borderDash: (context: ScriptableLineSegmentContext) =>
          getSegmentBorderDash(context),
      },
      backgroundColor: (context: ScriptableChartContext) =>
        getGradientFillBackgroundColor(context, 'rgba(243, 145, 55, 0.5)'),
      fill: true,
      radius: 1,
      hoverRadius: 10,
      hitRadius: 100,
    },
  ],
};

export const ARC_COLORS = [];

function getProjectionTableData(
  type: ProjectionTableType,
): ProjectionTableRow[] {
  return Array.from({ length: 5 }).map(() => {
    const actualData = WEEKS.map(() =>
      faker.number.float({ min: 1.0, max: 100.0, precision: 0.1 }),
    );

    return {
      name:
        type === 'product'
          ? faker.commerce.productName()
          : faker.commerce.productMaterial(),
      actualData,
      projectedData: actualData.map((actualValue) => {
        if (Math.random() < 0.2) {
          faker.number.float({ min: 1.0, max: 100.0, precision: 0.1 });
        }

        return actualValue;
      }),
      units: faker.number.int({ min: 10, max: 100 }),
      cost: faker.number.float({ min: 10.0, max: 100.0, precision: 0.01 }),
      revenue: faker.number.float({ min: 10.0, max: 100.0, precision: 0.01 }),
    };
  });
}

export const productProjectionTableData: ProjectionTableRow[] =
  getProjectionTableData('product');
export const materialProjectionTableData: ProjectionTableRow[] =
  getProjectionTableData('material');

export const DOUGHUT_CHART_COLORS = [
  '#5C59E8',
  '#0CAF60',
  '#F5B544',
  '#FD6A6A',
  '#6AA5FD',
];

export const productDoughnutChartArcData: DoughutChartArcData[] = Array.from({
  length: 5,
}).map((_, idx) => ({
  key: `${idx}`,
  amount: `${faker.number.float({ min: 10.0, max: 90.0, precision: 0.1 })}`,
  color: DOUGHUT_CHART_COLORS[idx],
  name: productProjectionTableData[idx].name,
  percentOfTotal: faker.number.float({
    min: 10.0,
    max: 90.0,
    precision: 0.1,
  }),
  description: faker.lorem.sentences(),
}));

export const PRODUCT_LABELS = productProjectionTableData.map((row) => row.name);
export const productStackedBarChartData = {
  labels: PRODUCT_LABELS,
  datasets: [
    {
      type: 'line' as const,
      label: 'Demand',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 3,
      radius: 0,
      hoverRadius: 10,
      hitRadius: 50,
      data: PRODUCT_LABELS.map(() => faker.number.int({ min: 100, max: 500 })),
    },
    {
      type: 'bar' as const,
      label: 'On hand',
      backgroundColor: DOUGHUT_CHART_COLORS[0],
      data: PRODUCT_LABELS.map(() => faker.number.int({ min: 10, max: 100 })),
    },
    {
      type: 'bar' as const,
      label: 'Can produce',
      backgroundColor: DOUGHUT_CHART_COLORS[1],
      data: PRODUCT_LABELS.map(() => faker.number.int({ min: 10, max: 100 })),
    },
    {
      type: 'bar' as const,
      label: 'Gap to demand',
      backgroundColor: DOUGHUT_CHART_COLORS[2],
      data: PRODUCT_LABELS.map(() => faker.number.int({ min: 10, max: 100 })),
    },
  ],
};

export const MATERIAL_LABELS = materialProjectionTableData.map(
  (row) => row.name,
);
export const materialStackedBarChartData = {
  labels: MATERIAL_LABELS,
  datasets: [
    {
      type: 'line' as const,
      label: 'Demand',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 3,
      radius: 0,
      hoverRadius: 10,
      hitRadius: 50,
      data: MATERIAL_LABELS.map(() => faker.number.int({ min: 100, max: 500 })),
    },
    {
      type: 'bar' as const,
      label: 'On hand',
      backgroundColor: DOUGHUT_CHART_COLORS[0],
      data: MATERIAL_LABELS.map(() => faker.number.int({ min: 10, max: 100 })),
    },
    {
      type: 'bar' as const,
      label: 'Gap to demand',
      backgroundColor: DOUGHUT_CHART_COLORS[2],
      data: MATERIAL_LABELS.map(() => faker.number.int({ min: 10, max: 100 })),
    },
  ],
};

// END MOCK S&OP DATA
