import {
  BucketObject,
  ConversationTopicType,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import {
  useCreateConversationMessage,
  useGetTopicConversation,
} from 'mc/api/endpoints/conversations';
import { FormEvent, Suspense, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetCompany } from 'mc/api/endpoints/companies';
import { useGetPurchase } from 'mc/api/endpoints/purchases';
import Icon from 'mc/features/common/components/icon';
import Spinner from 'mc/features/common/components/spinner';
import { getInitials } from 'mc/features/common/utils';

import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { cn } from '@/lib/utils';

import { ConversationContext } from '../utils';
import ConversationMessageList from './conversation-message-list';

export interface ConversationVendorAvatarProps {
  sellingCompany?: string;
}

export function ConversationVendorAvatar({
  sellingCompany,
}: ConversationVendorAvatarProps) {
  const { data: companyData } = useGetCompany(sellingCompany || '');

  if (!sellingCompany) {
    return <p className="py-6 text-center">No vendor selected</p>;
  }

  return (
    <div className="py-6 flex items-center">
      <Avatar>
        <AvatarFallback>
          {getInitials(companyData?.name || 'Lentil Warehouse')}
        </AvatarFallback>
      </Avatar>
      <div className="ml-4">
        <p className="text-sm font-medium">
          Conversation with {companyData?.name}
        </p>
      </div>
    </div>
  );
}

export function ConversationThreadDisabledMessage() {
  return (
    <div className="p-10 absolute left-0 top-0 right-0 bottom-0 flex items-center bg-white bg-opacity-10">
      <p className="text-center text-gray-500">
        Create the purchase order to start a conversation
      </p>
    </div>
  );
}

export default function ConversationThread() {
  const { conversationValues, setConversationValues } =
    useContext(ConversationContext);
  const [contents, setContents] = useState('');
  const [attachment, setAttachment] = useState<BucketObject>();
  const { purchaseId } = useParams();
  const [recipientEmail, setRecipientEmail] = useState('');
  const { data: conversationData, refetch } = useGetTopicConversation({
    topicRequest: {
      topicId: purchaseId || '',
      topicType: ConversationTopicType.Purchase,
    },
  });
  const { data: purchaseData } = useGetPurchase(purchaseId!!);

  useEffect(() => {
    if (conversationValues.contents) {
      setContents(conversationValues.contents);
    }
    if (conversationValues.attachment) {
      setAttachment(conversationValues.attachment);
    }
  }, [conversationValues]);

  const { mutateAsync: createMessage } = useCreateConversationMessage();

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      await createMessage({
        conversationId: conversationData?.id,
        topicRequest: {
          topicId: purchaseId || '',
          topicType: ConversationTopicType.Purchase,
        },
        emailTo: [recipientEmail],
        emailFrom: 'stephen+tfd@mammothclimate.com', // HACK
        emailContents: contents,
        emailSubject: `The Farmer's Dog has sent you a new message`,
        attachments: attachment ? [attachment] : [],
      });

      refetch();

      setContents('');
      setAttachment(undefined);
      setConversationValues({});
    } catch (e) {
      window.console.error(e);
    }
  }

  // can't send a message without a topic (purchase)
  const isDisabled = Boolean(!(purchaseId && recipientEmail && contents));

  if (!purchaseId) {
    return (
      <div className="h-full px-6 relative">
        <ConversationThreadDisabledMessage />
      </div>
    );
  }

  return (
    <Suspense fallback={<Spinner isLoading />}>
      <div className="h-full px-6 relative">
        <div className="h-full flex flex-col">
          <ConversationVendorAvatar
            sellingCompany={purchaseData?.sellingCompany}
          />

          <hr className="-mx-6" />

          <div className="py-6 flex-auto overflow-auto">
            <ConversationMessageList />
          </div>

          <hr className="-mx-6" />

          <div className="py-6">
            <form onSubmit={handleSubmit}>
              <div className="">
                <Input
                  type="email"
                  onChange={(e) => setRecipientEmail(e.target.value)}
                  placeholder="Recipient email address"
                />
              </div>
              <div className="mt-4 relative">
                <Textarea
                  className="pb-20 resize-none scroll-p-20"
                  placeholder="Type a message"
                  value={contents}
                  onChange={(e) => setContents(e.target.value)}
                />
                <div className="w-full px-3 py-2 flex justify-between absolute bottom-0 left-0">
                  <div className="flex items-center">
                    {/* todo: allow attachment */}
                    <Icon
                      width="16px"
                      className="text-gray-500 cursor"
                      name="attachment"
                    />
                    {attachment && (
                      <p className="ml-2 text-gray-400 text-xs font-medium">
                        {attachment.filename}
                      </p>
                    )}
                  </div>
                  <button
                    className={cn({ 'opacity-20': isDisabled })}
                    type="submit"
                    disabled={isDisabled}
                  >
                    <Icon width="16px" className="text-gray-500" name="send" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Suspense>
  );
}
