import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import * as _ from 'lodash-es';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

// TODO: may want to make these global options
const OPTIONS = {
  tension: 0.1,

  responsive: true,

  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    title: {
      display: false,
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
    },
  },
};

export interface LineChartProps {
  options?: object; // TODO
  data: ChartData<'line'>;
}

export function LineChart({ options = {}, data }: LineChartProps) {
  const mergedOptions = _.merge(OPTIONS, options);
  return <Line options={mergedOptions} data={data} />;
}
