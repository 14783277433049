import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { cn } from '@/lib/utils';

ChartJS.register(ArcElement, Tooltip, Legend);

const CHART_PADDING = 20;

export interface DoughutChartArcData {
  key: string;
  amount: string;
  color: string;
  name: string;
  percentOfTotal: number;
  description: string;
}

export interface DoughnutChartProps {
  title?: string;
  amount?: string;
  subtitle?: string;
  arcs: DoughutChartArcData[];
  onArcClick: (key: string) => void;
}

export default function DoughnutChart({
  title,
  amount,
  subtitle,
  arcs,
  onArcClick,
}: DoughnutChartProps) {
  const data = {
    labels: arcs.map((arc) => arc.name),
    datasets: [
      {
        data: arcs.map((arc) => arc.percentOfTotal),
        backgroundColor: arcs.map((arc) => arc.color),
      },
    ],
  };

  return (
    <div className="relative">
      {/* keeping this simple for now,
      can eventually write a title plugin for DoughnutChart */}
      <div
        className={cn(
          'w-full absolute top-1/2 text-center -translate-y-1/2 pointer-events-none',
          `px-[${CHART_PADDING}px]`,
        )}
      >
        {title && <p className="font-semibold">{title}</p>}
        {amount && <p className="mt-2 font-semibold text-5xl">{amount}</p>}
        {subtitle && (
          <p className="mt-2 text-gray-500 font-medium">{subtitle}</p>
        )}
      </div>

      <Doughnut
        data={data}
        options={{
          layout: {
            padding: CHART_PADDING,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          responsive: true,
          cutout: '90%',

          events: ['click'],
          onClick: (_, [arcElement]) => {
            if (!arcElement) {
              return;
            }

            onArcClick(arcs[arcElement.index].key);
          },

          datasets: {
            doughnut: {
              borderColor: (arc) => arcs[arc.dataIndex].color,

              borderWidth: 1,
              borderRadius: 20,

              hoverBorderWidth: 10,
            },
          },
        }}
      />
    </div>
  );
}
