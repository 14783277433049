import {
  EmissionsCategory,
  EmissionsListSort,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'mc/features/common/components/icon';
import {
  SortableTableHeadCell,
  TableBodyCellContents,
  TableBodyDataCell,
  TableHeadCellContents,
} from 'mc/features/common/components/table';
import { EMISSIONS_CATEGORY_TO_MEASUREMENT_UNITS } from 'mc/features/common/utils/units';

import { AspectRatio } from '@/components/ui/aspect-ratio';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

export interface FuTableRow {
  id: string;
  name: string;
  productCode: string;
  imgUrl: string;

  amountTotal: number;
  amountTotalPercentage: number;
  amountPerUnit: number;
  amountPerKg: number;
  amountBenchmark: number;

  benchmarkTooltipContent: ReactNode;
}

export function FuTableNameCell({
  functionalUnit,
}: {
  functionalUnit: FuTableRow;
}) {
  const { t } = useTranslation();

  return (
    <TableBodyCellContents>
      <div className="flex font-medium">
        <div className="w-[56px] min-w-[56px]">
          <AspectRatio ratio={1}>
            <img
              className="h-full w-full object-cover"
              alt={functionalUnit.name}
              src={functionalUnit.imgUrl}
            />
          </AspectRatio>
        </div>
        <div className="ml-6">
          <p className="font-semibold text-base leading-4">
            {functionalUnit.name}
          </p>
          <p className="text-gray-500 text-sm">
            {t('miscellaneous.product_code', { ns: 'global' })}:{' '}
            {functionalUnit.productCode}
          </p>
        </div>
      </div>
    </TableBodyCellContents>
  );
}

export interface FuTableBenchmarkCellProps {
  amount: number;
  tooltipContent?: ReactNode;
}

export function FuTableBenchmarkCell({
  amount,
  tooltipContent,
}: FuTableBenchmarkCellProps) {
  const { t } = useTranslation();
  let formattedAmount = t('formats.percent.one_decimal', {
    value: amount,
    ns: 'global',
  });
  if (amount > 0) {
    formattedAmount = `+ ${formattedAmount}`;
  }

  const children = (
    <p
      className={cn('p-3 rounded-lg font-semibold', {
        'bg-green-500 bg-opacity-10 text-green-500': amount > 0,
        'bg-red-500 bg-opacity-10 text-red-500': amount < 0,
      })}
    >
      {formattedAmount}
    </p>
  );

  if (!tooltipContent) {
    return <TableBodyCellContents>{children}</TableBodyCellContents>;
  }

  return (
    <TableBodyCellContents>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>{children}</TooltipTrigger>
          <TooltipContent className="w-[200px] border bg-white text-black">
            {tooltipContent}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </TableBodyCellContents>
  );
}

export interface FuTableProps {
  data: FuTableRow[];
  emissionsCategory: EmissionsCategory;
  onRowClick?: (fuId: string) => void;
  onSortClick?: (sortCategory: EmissionsListSort) => void;
}

export default function FuTable({
  data = [],
  emissionsCategory,
  onRowClick,
  onSortClick,
}: FuTableProps) {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const munits = EMISSIONS_CATEGORY_TO_MEASUREMENT_UNITS[emissionsCategory];

  /* eslint-disable react/no-unstable-nested-components */
  // disabled linting because we're defining inline components below
  // this is acceptable since the columns definition is memoized to emissions category
  const columns: ColumnDef<FuTableRow>[] = useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: () => (
          <SortableTableHeadCell
            className="w-[200px]"
            title={t('performance.fu_table.name', { ns: 'components' })}
          />
        ),
        cell: ({ row }) => <FuTableNameCell functionalUnit={row.original} />,
      },
      {
        id: EmissionsListSort.Total,
        accessorKey: EmissionsListSort.Total,
        header: () => (
          <SortableTableHeadCell
            onSortClick={
              onSortClick
                ? () => onSortClick(EmissionsListSort.Total)
                : undefined
            }
            title={t('performance.fu_table.total_emissions', {
              ns: 'components',
            })}
          />
        ),
        cell: ({ row }) => {
          const { amountTotal, amountTotalPercentage } = row.original;
          const formattedAmount = t('formats.decimal.two_decimal', {
            value: amountTotal,
            ns: 'global',
          });

          const percentText = t('formats.percent.zero_decimal', {
            value: amountTotalPercentage,
            ns: 'global',
          });
          const ofText = t(`emissions.${emissionsCategory.toLowerCase()}.of`);
          const subtitle = `${percentText} ${ofText}`;

          return (
            <TableBodyDataCell
              title={`${formattedAmount} ${munits}`}
              subtitle={subtitle}
            />
          );
        },
      },
      {
        id: EmissionsListSort.Unit,
        accessorKey: EmissionsListSort.Unit,
        header: () => (
          <SortableTableHeadCell
            onSortClick={
              onSortClick
                ? () => onSortClick(EmissionsListSort.Unit)
                : undefined
            }
            title={t('performance.fu_table.footprint', {
              ns: 'components',
            })}
          />
        ),
        cell: ({ row }) => {
          const { amountPerUnit } = row.original;
          const formattedAmount = t('formats.decimal.two_decimal', {
            value: amountPerUnit,
            ns: 'global',
          });

          return <TableBodyDataCell title={`${formattedAmount} ${munits}`} />;
        },
      },
      {
        id: EmissionsListSort.Weight,
        accessorKey: EmissionsListSort.Weight,
        header: () => (
          <SortableTableHeadCell
            onSortClick={
              onSortClick
                ? () => onSortClick(EmissionsListSort.Weight)
                : undefined
            }
            title={t('performance.fu_table.emissions_per_kg', {
              ns: 'components',
            })}
          />
        ),
        cell: ({ row }) => {
          const { amountPerKg } = row.original;
          const formattedAmount = t('formats.decimal.two_decimal', {
            value: amountPerKg,
            ns: 'global',
          });

          return <TableBodyDataCell title={`${formattedAmount} ${munits}`} />;
        },
      },
      {
        id: EmissionsListSort.Benchmark,
        accessorKey: EmissionsListSort.Benchmark,
        header: () => (
          <SortableTableHeadCell
            onSortClick={
              onSortClick
                ? () => onSortClick(EmissionsListSort.Benchmark)
                : undefined
            }
            title={t('performance.fu_table.benchmark', {
              ns: 'components',
            })}
          />
        ),
        cell: ({ row }) => {
          const { amountBenchmark } = row.original;

          return <FuTableBenchmarkCell amount={amountBenchmark} />;
        },
      },
      {
        id: 'arrow',
        cell: () => {
          if (!onRowClick) {
            return null;
          }

          return (
            <div className="pr-4 text-gray-400">
              <Icon name="chevron-right" width="16px" />
            </div>
          );
        },
      },
    ],
    [munits, emissionsCategory, t, onSortClick, onRowClick],
  );
  /* eslint-enable react/no-unstable-nested-components */

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className="hover:bg-transparent" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  <TableHeadCellContents>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </TableHeadCellContents>
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                className="cursor-pointer"
                data-state={row.getIsSelected() && 'selected'}
                key={row.id}
                onClick={
                  onRowClick ? () => onRowClick(row.original.id) : undefined
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    className={cn({ 'align-top': cell.column.id !== 'arrow' })}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className="py-8 text-center align-top"
              >
                <p className="font-medium">
                  {t('miscellaneous.no_results_found', { ns: 'global' })}
                </p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
