export interface PageHeaderProps {
  title: string;
  subtitle?: string;
}

export default function PageHeader({ title, subtitle }: PageHeaderProps) {
  return (
    <header>
      <h1 className="font-extralight text-5xl">{title}</h1>
      <h2 className="mt-2 text-gray-500 text-sm font-medium">{subtitle}</h2>
    </header>
  );
}
