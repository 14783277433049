import { Reference } from '@mammothclimate/mammoth_be/api/resources/reference';
import { cn } from 'mc/@/lib/utils';
import { getInitials } from 'mc/features/common/utils';

export interface ReferenceTileProps {
  reference: Reference;
  size?: 'md' | 'lg';
}

export default function ReferenceTile({
  reference,
  size = 'md',
}: ReferenceTileProps) {
  const classes = cn({
    'min-w-[56px] w-[56px] min-h-[56px] h-[56px] text-xl': size === 'md',

    'min-w-[198px] w-[198px] min-h-[198px] h-[198px] text-8xl': size === 'lg',
  });

  return (
    <div className={cn(classes, 'rounded-lg border')}>
      <div className="w-full h-full">
        <div className="h-full flex items-center">
          <p className="w-full text-gray-400 text-center">
            {getInitials(reference.displayValue || '')}
          </p>
        </div>
      </div>
    </div>
  );
}
