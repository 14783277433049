import * as _ from 'lodash-es';
import { cn } from 'mc/@/lib/utils';
import { DASHBOARD_PLANNING_INSIGHTS_ROUTE } from 'mc/app/routeUtils';
import {
  Insight,
  InsightGroup,
  InsightGroupTitles,
} from 'mc/mocks/factories/insights';
import { NavLink } from 'react-router-dom';

export interface InsightListItemProps {
  insight: Insight;
}

export function InsightListItem({ insight }: InsightListItemProps) {
  const isWarning = Math.random() < 0.6;

  return (
    <div>
      <NavLink
        className="hover:underline"
        to={DASHBOARD_PLANNING_INSIGHTS_ROUTE}
      >
        <span
          className={cn('font-semibold', {
            'text-green-500': !isWarning,
            'text-red-500': isWarning,
          })}
        >
          {insight.metric}
        </span>{' '}
        <span>{insight.metricDetails}</span>
      </NavLink>
    </div>
  );
}

export interface InsightListGroupProps {
  insights: Insight[];
  group: InsightGroup;
}

export function InsightListGroup({ insights, group }: InsightListGroupProps) {
  return (
    <div>
      <p className="text-lg font-semibold">{InsightGroupTitles[group]}</p>
      <ul className="p-4">
        {insights.map((insight) => (
          <li className="first:mt-0 mt-2" key={insight.id}>
            <InsightListItem insight={insight} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export interface InsightListProps {
  insights: Insight[];
}

export function InsightList({ insights }: InsightListProps) {
  const groups = _.groupBy(insights, 'group');

  return (
    <ul>
      {Object.entries(groups).map(([group, groupInsights]) => (
        <li className="first:mt-0 mt-6" key={group}>
          <InsightListGroup
            insights={groupInsights}
            group={group as InsightGroup}
          />
        </li>
      ))}
    </ul>
  );
}
