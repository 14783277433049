import * as _ from 'lodash-es';
import { Suspense, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EmissionsCategory,
  EmissionsLevel,
} from '@mammothclimate/mammoth_be/api/resources/internal';

import {
  useGetCategoryComparison,
  useGetEmissionsList,
} from 'mc/api/endpoints/emissions';
import useOrganizationId from 'mc/api/hooks/use-organization-id';

import { getTimelineFromYear } from '../utils';
import { GlobalFiltersContext } from '../utils/context';
import PerformanceHeroNumber, {
  PerformanceHeroNumberProps,
} from './hero-number';

const EMISSIONS_CATEGORY_TO_ICON = {
  [EmissionsCategory.Ghg]: 'car',
  [EmissionsCategory.Land]: 'park-bench',
  [EmissionsCategory.Water]: 'swimming-pool',
};

export default function PerformanceHeroNumbersSection() {
  const { t } = useTranslation();
  const { emissionsCategory, timelineYear } = useContext(GlobalFiltersContext);
  const organizationId = useOrganizationId();

  const { data: listData } = useGetEmissionsList({
    category: emissionsCategory,
    level: EmissionsLevel.Portfolio,
    parentLevel: EmissionsLevel.Portfolio,
    companyId: organizationId,
    sort: [],
    timeline: getTimelineFromYear(timelineYear),
  });

  const emissionsData = listData!![0].childEmissions[0];

  const { data: compData } = useGetCategoryComparison({
    category: emissionsCategory,
    emissions: emissionsData.totalEmissions,
  });

  if (!listData) {
    return null;
  }

  let formattedEmissionsCategory = _.startCase(emissionsCategory);
  if (emissionsCategory === EmissionsCategory.Ghg) {
    formattedEmissionsCategory = emissionsCategory.toUpperCase();
  }

  const heroCardProps: PerformanceHeroNumberProps[] = [
    {
      amount: emissionsData.totalEmissions,
      emissionsCategory,
      iconName: 'energy-charge',
      title: `${t('performance.hero_cards.portfolio_emissions', {
        ns: 'components',
      })} (${formattedEmissionsCategory})`,
      tooltipContent: t(
        `performance.hero_cards.portfolio_emissions_tooltip.${emissionsCategory.toLowerCase()}`,
        { ns: 'components' },
      ),
    },
    {
      amount: emissionsData.emissionsPerKg,
      emissionsCategory,
      iconName: 'coffee',
      title: `${t('performance.hero_cards.emissions_intensity', {
        ns: 'components',
      })} (per kg)`,
      tooltipContent: t(
        `performance.hero_cards.emissions_intensity_tooltip.${emissionsCategory.toLowerCase()}`,
        { ns: 'components' },
      ),
    },
    {
      amount: compData?.value || 0,
      units: compData?.comparison,
      emissionsCategory,
      iconName: EMISSIONS_CATEGORY_TO_ICON[emissionsCategory],
      title: t('performance.hero_cards.emissions_comparison', {
        ns: 'components',
      }),
      tooltipContent: t(
        `performance.hero_cards.emissions_comparison_tooltip.${emissionsCategory.toLowerCase()}`,
        { ns: 'components' },
      ),
    },
  ];

  return (
    <div className="-mx-3 flex items-stretch">
      <Suspense>
        {heroCardProps.map((props) => (
          <div className="w-1/3 mx-3" key={props.title}>
            <PerformanceHeroNumber
              amount={props.amount}
              emissionsCategory={emissionsCategory}
              iconName={props.iconName}
              title={props.title}
              tooltipContent={props.tooltipContent}
              units={props.units || undefined}
            />
          </div>
        ))}
      </Suspense>
    </div>
  );
}
