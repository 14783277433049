import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';

import { Checkbox } from 'mc/@/components/ui/checkbox';
import { cn } from 'mc/@/lib/utils';
import {
  SortableTableHeadCell,
  TableBodyCellContents,
  TableBodyDataCell,
} from 'mc/features/common/components/table';
import { WEEKS } from 'mc/mocks/factories/insights';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

import { DiffTable } from './diff-table';

export interface ProjectionTableRow {
  name: string;
  actualData: number[];
  projectedData: number[];
  units: number;
  cost: number;
  revenue: number;
}

export type ProjectionTableType = 'product' | 'material';

export interface ProjectionTableProps {
  data: ProjectionTableRow[];
  type: ProjectionTableType;
}

export function ProjectionTable({ data, type }: ProjectionTableProps) {
  /* eslint-disable react/no-unstable-nested-components */
  const columns: ColumnDef<any>[] = useMemo(
    () => [
      ...(type === 'material'
        ? [
            {
              id: 'checkbox',
              accessorKey: 'checkbox',
              header: () => <SortableTableHeadCell title="" />,
              cell: () => (
                <TableBodyCellContents>
                  <Checkbox onClick={() => {}} />
                </TableBodyCellContents>
              ),
            },
          ]
        : []),
      {
        id: 'name',
        accessorKey: 'name',
        header: () => <SortableTableHeadCell title="Name" />,
        // @ts-ignore
        cell: ({ row }) => <TableBodyDataCell title={row.original.name} />,
      },
      ...WEEKS.map((label, idx) => ({
        id: label,
        accessorKey: label,
        header: () => (
          <SortableTableHeadCell
            className="min-w-[300px] text-left"
            title={label}
          />
        ),
        // @ts-ignore
        cell: ({ row }) => {
          const timePeriodActualData = row.original.actualData[idx];
          // const timePeriodProjectedData = row.original.projectedData[idx];
          // const doesEqual = timePeriodActualData === timePeriodProjectedData;
          const doesEqual = Math.random() > 0.2;
          return (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <div
                    className={cn(
                      'min-w-[100px] px-4 p-2 rounded-lg text-white font-semibold text-center',
                      {
                        'bg-green-400': doesEqual,
                        'bg-red-400': !doesEqual,
                      },
                    )}
                  >
                    <p>{timePeriodActualData}</p>
                  </div>
                </TooltipTrigger>
                <TooltipContent className="w-[200px] border bg-white text-black">
                  <ul>
                    <li>
                      <span className="font-semibold">Value</span>:{' '}
                      {timePeriodActualData}
                    </li>
                    <li className={cn('mt-1', { 'text-red-400': !doesEqual })}>
                      <span className="font-semibold">Units</span>:{' '}
                      {row.original.units}
                    </li>
                    <li className="mt-1">
                      <span className="font-semibold">Cost</span>: $
                      {row.original.cost}
                    </li>
                    <li className="mt-1">
                      <span className="font-semibold">Revenue</span>: $
                      {row.original.revenue}
                    </li>
                  </ul>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        },
      })),
    ],
    [type],
  );
  /* eslint-enable react/no-unstable-nested-components */

  return <DiffTable data={data} columns={columns} />;
}
