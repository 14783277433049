import { EmissionsCategory } from '@mammothclimate/mammoth_be/api/resources/internal';

import Icon from 'mc/features/common/components/icon';
import InformationTooltip from 'mc/features/common/components/information-tooltip';
import { EMISSIONS_CATEGORY_TO_MEASUREMENT_UNITS } from 'mc/features/common/utils/units';

export interface PerformanceHeroNumberProps {
  amount: number;
  emissionsCategory: EmissionsCategory;
  iconName: string;
  title: string;
  tooltipContent: string | React.ReactNode;
  units?: string; // if provided, overrides the units
}

export default function PerformanceHeroNumber({
  amount,
  emissionsCategory,
  iconName,
  title,
  tooltipContent,
  units,
}: PerformanceHeroNumberProps) {
  const munits = EMISSIONS_CATEGORY_TO_MEASUREMENT_UNITS[emissionsCategory];
  // TODO
  const formattedAmount = Intl.NumberFormat('en-US', {
    notation: 'compact',
  }).format(amount);
  // const { t } = useTranslation();
  // const formattedAmount = t('formats.decimal.one_decimal', {
  //   value: amount,
  //   ns: 'global',
  // });
  const amountWithUnits = `${formattedAmount} ${munits}`;

  return (
    <div className="h-full p-6 border rounded-lg drop-shadow-sm bg-white">
      <div>
        <div className="flex items-center">
          <div className="flex-auto">
            <p className="font-medium text-xs lg:text-base">{title}</p>
          </div>
          <div>
            <InformationTooltip content={tooltipContent} />
          </div>
        </div>
        <div>
          <div className="mt-1 flex">
            <div className="p-2 self-center rounded-full bg-gray-100">
              <Icon name={iconName} />
            </div>
            <p
              className="ml-4 font-semibold text-4xl xl:text-5xl"
              aria-label={amountWithUnits}
            >
              {formattedAmount}
            </p>
            <span className="ml-2 self-end" aria-hidden="true">
              {units || munits}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
