import { useQueries, useQuery } from '@tanstack/react-query';

import useApiClient from '../hooks/use-api-client';

export const QUERY_KEY_PREFIX = 'companies';

export function useGetCompany(req: string) {
  const apiClient = useApiClient();

  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, req],
    queryFn: () => apiClient.internal.companies.getCompany(req),
  });
}

export function useGetCompanies(companyIds: string[]) {
  const apiClient = useApiClient();

  const queries = companyIds.map((companyId) => ({
    queryKey: [QUERY_KEY_PREFIX, companyId],
    queryFn: () => apiClient.internal.companies.getCompany(companyId),
  }));

  return useQueries({ queries });
}
