import { t } from 'i18next';
import { Component, ErrorInfo, ReactNode } from 'react';

interface NullErrorBoundaryProps {
  children?: ReactNode;
}

interface NullErrorBoundaryState {
  error?: Error;
}

export default class NullErrorBoundary extends Component<
  NullErrorBoundaryProps,
  NullErrorBoundaryState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      error: undefined,
    };
  }

  public static getDerivedStateFromError(error: Error): NullErrorBoundaryState {
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // log out an error so it's still caught by Highlight
    window.console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <p>{t('generic', { ns: 'errors' })}</p>;
    }

    return children;
  }
}
