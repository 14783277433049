import PageContainer from 'mc/features/common/components/page-container';
import { Section } from 'mc/features/common/components/section';
import { InsightList } from 'mc/features/planning/components/insight-list';
import { mockInsights } from 'mc/mocks/factories/insights';

export default function InsightsPage() {
  // const { data: insightsData } = useGetCompanyInsights();

  return (
    <PageContainer>
      <h1 className="text-4xl font-semibold">Insights</h1>

      <div className="mt-6">
        <Section>
          <InsightList insights={mockInsights} />
        </Section>
      </div>
    </PageContainer>
  );
}
