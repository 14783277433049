import {
  EmissionsCalculation,
  EmissionsLevel,
  EmissionsListSort,
} from '@mammothclimate/mammoth_be/api/resources/internal';
import {
  LifecycleStage,
  Reference,
} from '@mammothclimate/mammoth_be/api/resources/reference';
import * as _ from 'lodash-es';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useGetLifecycleStageBreakdown } from 'mc/api/endpoints/emissions';
import { useGetReferences } from 'mc/api/endpoints/references';
import { DASHBOARD_PERFORMANCE_INPUTS_ROUTE } from 'mc/app/routeUtils';
import { EMISSIONS_CATEGORY_TO_MEASUREMENT_UNITS } from 'mc/features/common/utils/units';
import DoughnutChart, {
  DoughutChartArcData,
} from 'mc/features/visualizations/components/doughnut-chart';
import InputTable from 'mc/features/visualizations/components/input-table';

import { AspectRatio } from '@/components/ui/aspect-ratio';
import { Button } from '@/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

import {
  LIFECYCLE_STAGE_TO_COLOR,
  LifecycleKey,
  transformInputEmissionsToInputRows,
} from '../utils';
import { GlobalFiltersContext } from '../utils/context';

export interface InputLifecycleSectionProps {
  parentLevel: EmissionsLevel;

  // if specified, request input emissions for the fu and allow toggling between kg/unit
  fuId?: string;
}

export default function InputLifecycleSection({
  // eslint-disable-next-line
  parentLevel,
  fuId,
}: InputLifecycleSectionProps) {
  const [selectedLifecycleStage, setSelectedLifecycleStage] = useState<
    LifecycleKey | undefined
  >();
  const [sort] = useState<EmissionsListSort[]>([]);
  const { emissionsCategory } = useContext(GlobalFiltersContext);

  const { t } = useTranslation();

  const { data: lcsData } = useGetLifecycleStageBreakdown({
    sort,
  });

  // TODO: assertions here!
  let lcsDataToShow = lcsData!![0];
  if (fuId) {
    lcsDataToShow = lcsData?.find(
      (lcsDatum) => lcsDatum.parentEntityId === fuId,
    )!!;
  }

  const { summary, list } = lcsDataToShow;
  const arcs: DoughutChartArcData[] = Object.entries(summary).map(
    ([key, value]) => {
      const lifecycleKey = key.toLowerCase() as LifecycleKey;

      return {
        key: lifecycleKey,
        amount:
          t('formats.decimal.one_decimal', { value: value?.emissions }) || '',
        color: LIFECYCLE_STAGE_TO_COLOR[key.toLowerCase()] || '',
        name: _.capitalize(key) || '',
        percentOfTotal: value?.percentOfParentEmissions || 0,
        description: value?.description || '',
      };
    },
  );

  const selectedArc = arcs.find((arc) => arc.key === selectedLifecycleStage);
  const title = selectedArc
    ? selectedArc.name
    : t('lifecycles.all.title', { ns: 'global' });
  const description = selectedArc
    ? selectedArc.description
    : t('lifecycles.all.description', { ns: 'global' });

  const lifecycleInputs = selectedLifecycleStage
    ? list[selectedLifecycleStage.toUpperCase() as LifecycleStage]
    : (Object.values(list).flat() as EmissionsCalculation[]); // TODO: sort for all lifecycles

  // HACK: should get all of these references in one request at most
  const referenceIds = _.map(lifecycleInputs, 'entityId');
  const inputsData = useGetReferences(referenceIds);
  if (_.map(inputsData, 'isLoading').find(Boolean)) {
    return null;
  }

  // TODO: assertions!
  const inputTableRows = transformInputEmissionsToInputRows(
    lifecycleInputs || [],
    _.map(inputsData, 'data') as Reference[],
    selectedLifecycleStage,
  );

  inputTableRows.length = Math.min(inputTableRows.length, 5);

  return (
    <div>
      <div className="py-6 px-6 flex justify-between items-center ">
        <h3 className="text-2xl font-semibold">
          {t('performance.sections.top_emissions_by_inputs.title', {
            ns: 'pages',
          })}
        </h3>
        <Button type="button" variant="outline" size="lg" asChild>
          <Link to={DASHBOARD_PERFORMANCE_INPUTS_ROUTE}>
            {t('performance.sections.top_emissions_by_inputs.view_all', {
              ns: 'pages',
            })}
          </Link>
        </Button>
      </div>
      <hr />

      <div className="px-10 xl:px-20 mt-6 flex items-center">
        <div className="w-1/2">
          <AspectRatio ratio={1}>
            <div>
              <DoughnutChart
                title={title}
                amount={
                  selectedArc
                    ? t('formats.percent.one_decimal', {
                        value: selectedArc?.percentOfTotal,
                        ns: 'global',
                      })
                    : undefined
                }
                subtitle={
                  selectedArc
                    ? `=${t('formats.decimal.one_decimal', {
                        value: selectedArc?.amount,
                        ns: 'global',
                      })} ${
                        EMISSIONS_CATEGORY_TO_MEASUREMENT_UNITS[
                          emissionsCategory
                        ]
                      }`
                    : undefined
                }
                arcs={arcs}
                onArcClick={(key) =>
                  setSelectedLifecycleStage(key as LifecycleKey)
                }
              />
            </div>
          </AspectRatio>
        </div>
        <div className="w-1/2">
          <h4 className="text-xl font-semibold">{title}</h4>
          <p className="mt-2 font-medium">{description}</p>
          <div className="mt-6">
            {arcs.map((arc) => (
              <div
                key={arc.key}
                className="mr-4 mb-2 inline-flex items-center text-gray-500"
              >
                <div
                  className="w-1 h-1 p-1 rounded-full"
                  style={{ backgroundColor: arc.color }}
                />
                <p className="ml-2 font-medium text-xs">
                  {arc.name} (
                  {t('formats.percent.zero_decimal', {
                    value: arc.percentOfTotal,
                    ns: 'global',
                  })}
                  )
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="px-6 mt-6 flex items-center justify-between">
        <div>
          <p className="font-semibold text-sm">
            {t('performance.sections.top_emissions_by_inputs.input_filters', {
              ns: 'pages',
            })}
          </p>
        </div>
        <div>
          <Tabs
            defaultValue=""
            value={selectedLifecycleStage}
            onValueChange={(key) => {
              setSelectedLifecycleStage(key as LifecycleKey);
            }}
          >
            <TabsList className="bg-gray-100">
              <TabsTrigger value="">
                {t('lifecycles.all.title', { ns: 'global' })}
              </TabsTrigger>
              <TabsTrigger value="materials">
                {t('lifecycles.materials.title', { ns: 'global' })}
              </TabsTrigger>
              <TabsTrigger value="manufacturing">
                {t('lifecycles.manufacturing.title', { ns: 'global' })}
              </TabsTrigger>
              <TabsTrigger value="packaging">
                {t('lifecycles.packaging.title', { ns: 'global' })}
              </TabsTrigger>
              <TabsTrigger value="distribution">
                {t('lifecycles.distribution.title', { ns: 'global' })}
              </TabsTrigger>
              <TabsTrigger value="waste">
                {t('lifecycles.waste.title', { ns: 'global' })}
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>

      <hr className="mt-6" />

      <InputTable
        data={inputTableRows}
        emissionsCategory={emissionsCategory}
        // onSortClick={(sortCategory) => setSort([sortCategory])}
        showPercentageBars={false}
      />
    </div>
  );
}
