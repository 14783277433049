// import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { ACCOUNT_LOGIN_ROUTE } from 'mc/app/routeUtils';
import Spinner from 'mc/features/common/components/spinner';

export interface ProtectedRouteProps {
  children?: ReactNode;
}

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  // const { isAuthenticated, isLoading } = useKindeAuth();
  const isLoading = false;
  const isAuthenticated = true;

  if (!isLoading) {
    if (isAuthenticated) {
      return children || null;
    }

    return <Navigate replace to={ACCOUNT_LOGIN_ROUTE} />;
  }

  return (
    <div className="w-screen h-screen">
      <Spinner
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        isLoading
      />
    </div>
  );
}
