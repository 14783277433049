import { QueryCache, QueryClient } from '@tanstack/react-query';
// import { t } from 'i18next';

// import { toast } from '@/components/ui/use-toast';

export default new QueryClient({
  queryCache: new QueryCache({
    // TODO: create different error toasts/messaging for different situations
    // https://linear.app/mammothclimate/issue/MAM-323/add-more-comprehensive-error-messaging
    // onError: () => {
    //   toast({
    //     variant: 'destructive',
    //     // TODO: possibility of triggering a toast before locale files are loaded
    //     // (unlikely but still a possibility)
    //     // https://linear.app/mammothclimate/issue/MAM-324/ensure-error-content-is-loaded-before-any-error-toast-could-be-shown
    //     title: t('generic', { ns: 'errors' }),
    //     description: t('invalid_request', { ns: 'errors' }),
    //   });
    // },
  }),
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: Infinity,
      suspense: true,
      useErrorBoundary: (error) =>
        (error as unknown as any).response?.status >= 500,
    },
  },
});
