import * as _ from 'lodash-es';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { Button } from 'mc/@/components/ui/button';
import { Input } from 'mc/@/components/ui/input';
import { useGetCompanies } from 'mc/api/endpoints/companies';
import { useGetCompanyContracts } from 'mc/api/endpoints/contracts';
import { useGetReferences } from 'mc/api/endpoints/references';
import { DASHBOARD_ORDERS_NEW_ROUTE } from 'mc/app/routeUtils';
import PageContainer from 'mc/features/common/components/page-container';
import ReferenceTile from 'mc/features/orders/components/reference-tile';

export default function ItemsIndexPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [query, setQuery] = useState('');

  const { data: contractsData } = useGetCompanyContracts({});
  const vendorsData = useGetCompanies(
    _.uniq(contractsData?.map((contract) => contract.sellingCompany) || []),
  );
  const referencesData = useGetReferences(
    _.uniq(contractsData?.map((contract) => contract.referenceId) || []),
  );
  const allReferences = referencesData.map(({ data }) => data).filter(Boolean);

  const filteredReferences = query
    ? allReferences.filter(
        (reference) =>
          reference?.displayValue.toLowerCase().startsWith(query.toLowerCase()),
      )
    : allReferences;

  return (
    <PageContainer className="pt-0">
      <div className="py-10 -mx-10 bg-white text-center">
        <h1 className="py-2 text-4xl">Items</h1>
        <h2>These are all the items currently used in your product lines</h2>
        <div className="mt-10">
          <Input
            className="max-w-[600px] mx-auto"
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search for an item..."
          />
        </div>
      </div>

      <ul className="-mx-5 mt-10 flex flex-wrap">
        {contractsData?.map((contract) => {
          const reference = filteredReferences.find(
            (_reference) => _reference?.id === contract.referenceId,
          );

          if (!reference) {
            // NOTE: shouldn't ever reach this state
            return null;
          }

          const vendorData = vendorsData?.find(
            ({ data }) => data?.id === contract?.sellingCompany,
          );

          const vendor = vendorData?.data;

          return (
            <li
              className="p-6 mt-5 mx-5 rounded-md drop-shadow-sm bg-white"
              key={contract.id}
            >
              <ReferenceTile reference={reference} size="lg" />
              <p className="mt-4">{reference.displayValue}</p>
              <p className="mt-4 font-medium">{vendor?.name}</p>
              <p className="mt-4 font-medium">
                {t('formats.currency.two_decimal', {
                  displayValue: reference.displayValue,
                  value: contract?.unitCost,
                })}
                /{contract?.unitOfMeasurement}
              </p>
              <Button
                className="w-full mt-4 font-normal"
                variant="outline"
                size="lg"
                type="button"
                onClick={() => {
                  const params = {
                    referenceId: reference.id || '',
                    unitCost: `${contract?.unitCost}` || '',
                    sellingCompany: contract?.sellingCompany || '',
                  };

                  navigate({
                    pathname: DASHBOARD_ORDERS_NEW_ROUTE,
                    search: `?${createSearchParams(params)}`,
                  });
                }}
              >
                Create Order
              </Button>
            </li>
          );
        })}
      </ul>
    </PageContainer>
  );
}
